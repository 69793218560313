@charset "UTF-8";

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --endreox-font: 'Barlow', sans-serif;
  --endreox-font-two: 'Roboto', sans-serif;
  --endreox-font-three: 'Open Sans', sans-serif;
  --endreox-font-four: 'freehand521_btregular';
  --endreox-font-five: 'Yantramanav', sans-serif;
  --endreox-gray: #444444;
  --endreox-gray-rgb: 68, 68, 68;
  --endreox-white: #ffffff;
  --endreox-white-rgb: 255, 255, 255;
  --endreox-base: #58ABCF;
  --endreox-base-rgb: 203, 17, 30;
  --endreox-black: #58ABCF;
  --endreox-black-rgb: 2, 14, 79;
  --endreox-primary: #58ABCF;
  --endreox-primary-rgb: 0, 122, 239;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}
.iconColor {
  color:white;
  font-size: 30px;
}
.main-menu__main-menu-box .hideLinks, .main-menu__main-menu-box .showLinks {
  align-items: start;
  display: inline-flex;
  gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

@-webkit-keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(-30px);
  }
}

@keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(-30px);
  }
}

.float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

#counter {
  background: rgba(0, 0, 0, 0.05);
  padding: 25px;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

#counter li {
  flex: 1;
  text-align: center;
  font-size: 50px;
}

#counter span.percent:after {
  content: "%";
  display: inline-block;
}

body {
  font-family: var(--endreox-font);
  color: var(--endreox-gray);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--endreox-gray);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--endreox-black);
  margin: 0;
  font-family: var(--endreox-font-two);
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--endreox-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/

.scrollTop {
  position: fixed;
  background: #CE9B4E;
  border: none;
  height: 3.4047226798462384vw;
  width: 3.4047226798462384vw;
  border-radius: 50%;
  color: white;
  right: 15px;
  text-align: 'center';
  transition: background-image .3s, opacity .5s, visibility .5s;
  transform: translate(100px, 50px);
  transition: 0.5s;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.main-menu__main-menu-box .menu-icon {
  display: none;
}

.show_btn {
  visibility: visible;
  transform: translate(0px, 0px);
  transition: 0.5s;
  top: 20.409648544755626vw;
  z-index: 999;
}

.mail_btn {
  position: fixed;
  background: #CE9B4E;
  border: none;
  height: 3.4047226798462384vw;
  width: 3.4047226798462384vw;
  border-radius: 50%;
  color: white;
  right: 15px;
  text-align: 'center';
  transition: background-image .3s, opacity .5s, visibility .5s;
  transform: translate(100px, 50px);
  transition: 0.5s;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.mail_btn {
  visibility: visible;
  transform: translate(0px, 0px);
  transition: 0.5s;
  top: 16.339648544755626vw;
  z-index: 999;
}

.mail_btn:active,
.mail_btn:hover {
  background-color: #CE9B4E;
}

.appointment_btn:active,
.appointment_btn:hover,
.appointment_btn:focus-visible {
  border: #CE9B4E !important;
  background-color: #CE9B4E !important;
  box-shadow: none !important;

}


.appointment_btn svg {
  height: 1.4047226798462384vw;
  width: 1.4047226798462384vw;

}

.mail_btn svg {
  height: 1.4047226798462384vw;
  width: 1.4047226798462384vw;

}

.show_btn svg {
  height: 1.4047226798462384vw;
  width: 1.4047226798462384vw;

}


.appointment_btn {
  position: fixed;
  background: #CE9B4E;
  border: none;
  height: 3.4047226798462384vw;
  width: 3.4047226798462384vw;
  border-radius: 50%;
  color: white;
  right: 15px;
  text-align: 'center';
  transition: background-image .3s, opacity .5s, visibility .5s;
  transform: translate(100px, 50px);
  transition: 0.5s;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.appointment_btn_yellow button:active,
.appointment_btn_yellow button:hover,
.appointment_btn_yellow button:focus-visible {
  background-color: #CE9B4E !important;
  border: #CE9B4E !important;
  box-shadow: none !important;
}

.appointment_btn_yellow button {
  background-color: #CE9B4E;
  border: #CE9B4E;

}

.appointment_btn_yellow {
  text-align: center;
}

.appointment_btn {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  transition: 0.5s;
  top: 12.329648544755626vw;
  z-index: 999;
}

.show_btn img {
  width: 1.6474464579901154vw;
  height: 1.672872048325096vw;
}

.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--endreox-base);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}

.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--endreox-base);
  opacity: .3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width .3s, height .3s, opacity .3s;
  transition: width .3s, height .3s, opacity .3s;
  z-index: 999991;
}

.custom-cursor__hover {
  background-color: var(--endreox-base);
  opacity: 0.4;
}

.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: .4;
}

.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  outline: none !important;
  padding: 14px 35px 14px;
  background-color: #58ABCF;
  color: var(--endreox-white);
  font-size: 15px;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 29px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
  border-radius: 8px;
}

.thm-btn::before {
  position: absolute;
  content: '';
  background-color: var(--endreox-black);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scaleY(0) rotateX(0deg);
  transform: scaleY(0) rotateX(0deg);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: -1;
}

.thm-btn:hover {
  color: var(--endreox-white);
}

/* .thm-btn:hover:before {
  -webkit-transform: scaleY(1) rotateX(0deg);
  transform: scaleY(1) rotateX(0deg);
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
} */

.section-title {
  position: relative;
  display: block;
  margin-top: -12px;
  margin-bottom: 49px;
}

.section-title__tagline {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: var(--endreox-primary);
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: var(--endreox-font-two);
  margin-left: 38px;
  z-index: 1;
}

.section-title__tagline:before {
  position: absolute;
  left: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.section-title__title {
  margin: 0;
  color: var(--endreox-black);
  /* font-size: 45px; */
  line-height: 54px;
  font-weight: 900;
  margin-top: 11px;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.form-select {

  height: 48px;
  border-radius: 8px;
}

.bootstrap-select .dropdown-menu>li+li>a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu>li.selected>a {
  background: var(--endreox-primary);
  color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--endreox-base);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
  background: var(--endreox-black);
  color: var(--endreox-white);
  cursor: pointer;
}

/* Preloader */
.handle-preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  z-index: 9999999;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 0 auto 45px auto;
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  position: relative;
  display: inline-block;
  font-family: var(--endreox-font);
  font-weight: 700;
  letter-spacing: 15px;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  -webkit-animation: letters-loading 4s infinite;
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(9):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.handle-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  color: #ffffff;
}

.handle-preloader .animation-preloader .spinner {
  border: 3px solid #ffffff;
  border-top-color: rgba(255, 255, 255, 0.7);
}

/* AnimaciÃƒÆ’Ã‚Â³n del preloader */
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

.loader-wrap .layer-one {
  position: absolute;
  left: 0%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-two {
  position: absolute;
  left: 33.3333%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-three {
  position: absolute;
  left: 66.6666%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--endreox-base);
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: var(--endreox-primary);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
}

.scroll-to-top i {
  color: var(--endreox-white);
  font-size: 18px;
  line-height: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.scroll-to-top:hover {
  background-color: var(--endreox-base);
}

.scroll-to-top:hover i {
  color: #fff;
}

/** xs sidebar **/
.xs-sidebar-group .xs-overlay {
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  /* cursor: url(../images/icon/cross-out.png), pointer; */
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  z-index: 99999999;
}

.xs-sidebar-group.isActive .xs-overlay {
  opacity: .6;
  visibility: visible;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  right: 100%;
  background-color: #000000;
}

.xs-sidebar-widget {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  max-width: 460px;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 700ms ease 100ms;
  transition: all 700ms ease 100ms;
  visibility: hidden;
  opacity: 1;
  z-index: 999999999;
}

.xs-sidebar-group.isActive .xs-sidebar-widget {
  opacity: 1;
  visibility: visible;
  right: 0;
  -webkit-transition: all 500ms ease 800ms;
  transition: all 500ms ease 800ms;
}

.sidebar-widget-container {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 300ms ease 100ms;
  transition: all 300ms ease 100ms;
}

.xs-sidebar-group.isActive .sidebar-widget-container {
  top: 0px;
  opacity: 1;
  visibility: visible;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 700ms ease 1500ms;
  transition: all 700ms ease 1500ms;
}

.xs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
  z-index: 0;
  background-color: #000000;
}

.sidebar-textwidget {
  padding: 50px 45px;
}

.xs-sidebar-group .widget-heading {
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px;
}

.xs-sidebar-group .widget-heading a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--endreox-black);
  border-radius: 50%;
  color: var(--endreox-black);
  font-size: 20px;
  font-weight: 400;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.xs-sidebar-group .widget-heading a:hover {
  color: var(--endreox-base);
  border-color: var(--thn-base);
}

.xs-sidebar-group .content-inner .logo {
  position: relative;
  display: block;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 30px;
}

.xs-sidebar-group .content-inner .logo a {
  position: relative;
  display: inline-block;
}

.xs-sidebar-group .content-inner h3 {
  color: var(--endreox-black);
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 22px;
}

.xs-sidebar-group .content-inner .content-box {
  position: relative;
  display: block;
  padding-top: 33px;
  padding-bottom: 47px;
}

.xs-sidebar-group .content-inner .content-box .inner-text {
  position: relative;
  display: block;
}

.xs-sidebar-group .content-inner .content-box p {
  color: #444444;
}

.xs-sidebar-group .content-inner .sidebar-contact-info {
  position: relative;
  display: block;
}

.xs-sidebar-group .content-inner .sidebar-contact-info h3 {
  color: var(--endreox-black);
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 27px;
}

.sidebar-contact-info ul {
  position: relative;
  display: block;
}

.sidebar-contact-info ul li {
  position: relative;
  display: block;
  margin-bottom: 26px;
}

.sidebar-contact-info ul li:last-child {
  margin-bottom: 0;
}

.sidebar-contact-info ul li .inner {
  position: relative;
  display: block;
  padding-left: 45px;
}

.sidebar-contact-info ul li .inner .icon {
  position: absolute;
  top: 3px;
  left: 0;
}

.sidebar-contact-info ul li:first-child .inner .icon {
  top: 11px;
}

.sidebar-contact-info ul li .inner .icon span {
  position: relative;
  display: block;
  color: var(--endreox-base);
  font-size: 32px;
  line-height: 25px;
}

.sidebar-contact-info ul li .inner .icon span.map-marker {
  position: relative;
  top: 11px;
  font-size: 34px;
}

.sidebar-contact-info ul li .inner .text {
  position: relative;
  display: block;
}

.sidebar-contact-info ul li .inner .text p {
  line-height: 24px;
  color: #444444;
}

.sidebar-contact-info ul li .inner .text p a {
  color: #444444;
}

.xs-sidebar-group .content-inner .side-content-newsletter-box {
  position: relative;
  display: block;
  padding-top: 49px;
}

.xs-sidebar-group .content-inner .side-content-newsletter-box h3 {
  color: var(--endreox-black);
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 24px;
}

.side-content-newsletter-box .form-group input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  color: #444444;
  font-size: 18px;
  font-weight: 400;
  background-color: #f8f8f8;
  border: 1px solid #e3dfdf;
  padding-left: 30px;
  padding-right: 20px;
  border-radius: 6px;
  font-family: var(--endreox-font);
  outline: none;
}

.side-content-newsletter-box .form-group button {
  margin-top: 30px;
  border: none;
}

.side-content-newsletter-box .form-group button span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 16px;
  margin-left: 15px;
  top: -1px;
}

/*--------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 999;
}

.main-menu {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu__wrapper {
  position: relative;
  display: block;
  padding: 0 100px;
  z-index: 1;
}

.main-menu__wrapper-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  z-index: 1;
}

.main-menu__left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-menu__logo {
  position: relative;
  display: block;
  /* padding: 32px 0 42px; */
  margin-right: 65px;
}

.main-menu__main-menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu__search-box {
  position: relative;
  display: block;
  margin-left: 25px;
}

.main-menu__search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__search:hover {
  color: var(--endreox-base);
}

.main-menu__right {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu__call-btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 49px 0 48px;
}

.main-menu__call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 0;
}

.main-menu__call-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu__call-icon span {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: var(--endreox-white);
}

.main-menu__call-number {
  margin-left: 15px;
}

.main-menu__call-number p {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.main-menu__call-number p a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__call-number p a:hover {
  color: var(--endreox-base);
}

.main-menu__btn-box {
  position: relative;
  display: block;
  margin-left: 35px;
}

.main-menu__btn {
  font-size: 14px;
  color: #030721;
  background-color: #CE9B4E;
  padding: 6px 32px 4px;
  border-radius: 8px;
}

.main-menu__btn::before {
  background-color: var(--endreox-white);
}

.main-menu__btn:hover {
  color: white;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

@media (min-width: 1200px) {

  .main-menu .main-menu__list,
  .main-menu .main-menu__list>li>ul,
  .main-menu .main-menu__list>li>ul>li>ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list>li>ul,
  .stricky-header .main-menu__list>li>ul>li>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu .main-menu__list>li {
  padding-top: 53px;
  padding-bottom: 54px;
  position: relative;
}

.stricky-header .main-menu__list>li {
  padding-top: 35px;
  padding-bottom: 35px;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
  margin-left: 45px;
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--endreox-white);
  font-weight: 500;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.servicename {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--endreox-white);
  font-weight: 500;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;

}


.servicename.active {
  color: #009cde;
  font-weight: 900;
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
  color: var(--endreox-base);
}

.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: 99;
  background-color: #0a1936;
  padding: 10px 25px 10px;
  border-top: 2px solid var(--endreox-base);
}

.main-menu__list li a.active {
  color: #009cde;
  font-weight: 900;
}

.main-menu .main-menu__list>li>ul>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.main-menu .main-menu__list>li:hover>ul,
.main-menu .main-menu__list>li>ul>li:hover>ul,
.stricky-header .main-menu__list>li:hover>ul,
.stricky-header .main-menu__list>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list>li>ul>li,
.main-menu .main-menu__list>li>ul>li>ul>li,
.stricky-header .main-menu__list>li>ul>li,
.stricky-header .main-menu__list>li>ul>li>ul>li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #343c4e;
}

.main-menu .main-menu__list>li>ul>li:last-child,
.main-menu .main-menu__list>li>ul>li>ul>li:last-child,
.stricky-header .main-menu__list>li>ul>li:last-child,
.stricky-header .main-menu__list>li>ul>li>ul>li:last-child {
  border-bottom: 0;
}

.main-menu .main-menu__list>li>ul>li+li,
.main-menu .main-menu__list>li>ul>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li>ul>li+li {
  border-top: none;
  margin-top: 0px;
}

.main-menu .main-menu__list>li>ul>li>a,
.main-menu .main-menu__list>li>ul>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>ul>li>a {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: var(--endreox-white);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0px 12px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .main-menu__list>li>ul>li:hover>a,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a {
  color: var(--endreox-base);
}

.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  top: 0;
  left: 112%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

.stricky-header.main-menu {
  background-color: #030721;
}

.stricky-header .main-menu__logo {
  padding: 25px 0 25px;
}

.stricky-header .main-menu__call-btn-box {
  padding: 30px 0 30px;
}

.stricky-header .main-menu-two__logo {
  padding: 25px 0 25px;
}

.mobile-nav__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--endreox-base);
  cursor: pointer;
}

.mobile-nav__buttons a+a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--endreox-base);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--endreox-base);
  cursor: pointer;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--endreox-white);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--endreox-black);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--endreox-white);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0.5em;
}

.mobile-nav__content .main-menu__list>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li>ul>li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list>li>a>.main-menu-border {
  display: none !important;
}

.mobile-nav__content .main-menu__list>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--endreox-font);
  font-weight: 500;
  height: 46px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>ul>li>a.expanded {
  color: var(--endreox-base);
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--endreox-base);
}

.mobile-nav__content .main-menu__list>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button {
  width: 30px;
  height: 30px;
  background-color: var(--endreox-base);
  border: none;
  outline: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--endreox-base);
}

/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>ul {
  display: none !important;
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--endreox-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--endreox-white);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__social a+a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--endreox-base);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--endreox-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__contact li+li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--endreox-base);
}

.mobile-nav__contact li>i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--endreox-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}

/*--------------------------------------------------------------
# Navigations Two
--------------------------------------------------------------*/
.main-header-two {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 999;
}

.main-menu-two__wrapper {
  position: relative;
  display: block;
  padding: 0px 100px;
  z-index: 1;
}

.main-menu-two__wrapper-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  z-index: 1;
}

.main-menu-two__left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-menu-two__logo {
  position: relative;
  display: block;
  padding: 45px 0 42px;
  margin-right: 65px;
}

.main-menu-two__main-menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu-two__search-box {
  position: relative;
  display: block;
  margin-left: 25px;
}

.main-menu-two__search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__search:hover {
  color: var(--endreox-base);
}

.main-menu-two__right {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu-two__call-btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 49px 0 48px;
}

.main-menu-two__call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu-two__call-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 8px;
}

.main-menu-two__call-icon span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #dbae18;
}

.main-menu-two__call-number {
  margin-left: 15px;
}

.main-menu-two__call-number h5 {
  font-size: 16px;
  color: var(--endreox-white);
  font-weight: 400;
  font-family: var(--endreox-font);
  line-height: 26px;
  margin-bottom: 6px;
}

.main-menu-two__call-number p {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.main-menu-two__call-number p a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__call-number p a:hover {
  color: var(--endreox-base);
}

/*--------------------------------------------------------------
# Navigations Three
--------------------------------------------------------------*/
.main-header-three {
  position: relative;
  display: block;
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 999;
}

.main-header-three__top {
  position: relative;
  display: block;
  background-color: #f2f0f0;
  z-index: 2;
}

.main-header-three__top-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 0;
}

.main-header-three__top-left {
  position: relative;
  display: block;
}

.main-header-three__top-left-text {
  color: #162b62;
  font-family: var(--endreox-font-five);
}

.main-header-three__top-left-text a {
  color: var(--endreox-base);
  font-weight: 700;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-three__top-left-text a:hover {
  color: var(--endreox-primary);
}

.main-header-three__top-social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-three__top-social a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  color: #797878;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-three__top-social a+a {
  margin-left: 25px;
}

.main-header-three__top-social a:hover {
  color: var(--endreox-base);
}

.main-header-three__middle {
  position: relative;
  display: block;
}

.main-header-three__middle-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 27px 0 63px;
}

.main-header-three__logo {
  position: relative;
  display: block;
}

.main-header-three__contact-box {
  position: relative;
  display: block;
}

.main-header-three__contact-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-three__contact-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-three__contact-list li+li {
  margin-left: 45px;
}

.main-header-three__contact-list li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: -2px;
}

.main-header-three__contact-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: var(--endreox-base);
}

.main-header-three__contact-list li .content {
  margin-left: 15px;
}

.main-header-three__contact-list li .content h5 {
  font-size: 14px;
  font-weight: 700;
  color: var(--endreox-primary);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 14px;
}

.main-header-three__contact-list li .content p {
  font-weight: 500;
}

.main-header-three__contact-list li .content p a {
  color: var(--endreox-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-three__contact-list li .content p a:hover {
  color: var(--endreox-base);
}

.main-menu-three__wrapper {
  position: relative;
  display: block;
  margin-top: -41px;
}

.main-menu-three__wrapper-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-menu-three__left {
  position: relative;
  display: block;
  background-color: #162b62;
  padding-right: 10px;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  z-index: 1;
}

.main-menu-three__left:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 99px;
  left: -1000000px;
  background-color: #162b62;
  z-index: -1;
}

.main-menu-three__main-menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu-three__search-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 52px;
}

.main-menu-three__search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__search:hover {
  color: var(--endreox-base);
}

.main-menu-three__side-content-icon {
  position: relative;
  display: block;
}

.main-menu-three__side-content-icon .navSidebar-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: var(--endreox-base);
  border-radius: 50%;
}

.main-menu-three__right {
  position: relative;
  display: block;
}

.main-menu-three__btn-box {
  position: relative;
  display: block;
}

.main-menu-three__btn {
  font-size: 14px;
  padding: 21px 50px 19px;
  border-radius: 35px;
  border-top-right-radius: 0;
  background-color: var(--endreox-primary);
}

.stricky-header.main-menu-three {
  background-color: var(--endreox-black);
}

.main-menu-three .main-menu__list>li,
.stricky-header.main-menu-three .main-menu__list>li {
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-menu-three .main-menu__list>li+li,
.stricky-header.main-menu-three .main-menu__list>li+li {
  margin-left: 45px;
}

.stricky-header .main-menu-three__wrapper {
  margin-top: 0;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.search-popup.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--endreox-black);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--endreox-base);
  border: 0;
}

.search-popup__content .thm-btn i {
  height: auto;
  width: auto;
  background-color: transparent;
  border-radius: 50%;
  color: var(--endreox-white);
  font-size: 20px;
  line-height: inherit;
  text-align: center;
  top: 0;
  margin-right: 0;
  padding-left: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

/*--------------------------------------------------------------
# Main Slider
--------------------------------------------------------------*/
.main-slider {
  position: relative;
  display: block;
  background-color: #030721;
  z-index: 1;
}

.main-slider__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.main-slider__big-text {
  position: absolute;
  bottom: 135px;
  left: -425px;
  font-size: 201px;
  font-family: var(--endreox-font-two);
  font-weight: 900;
  line-height: 201px;
  color: transparent;
  -webkit-text-stroke: 1px #4d5f7c;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: .40;
  z-index: 10;
}

.main-slider__wrap {
  position: relative;
  display: block;
  padding: 241px 0 250px;
  overflow: hidden;
  z-index: 1;
}

.main-slider__inner {
  position: relative;
  display: block;
  max-width: 680px;
  width: 100%;
  z-index: 1;
}

.main-slider__title {
  font-size: 60px;
  color: var(--endreox-white);
  font-weight: 900;
  line-height: 63px;
}

.main-slider__title span {
  color: #58ABCF;
}

.main-slider__text {
  font-size: 22px;
  color: var(--endreox-white);
  font-weight: 500;
  line-height: 32px;
  padding-top: 29px;
  padding-bottom: 42px;
}

.main-slider__three-facts {
  position: absolute;
  right: 0;
  bottom: -216px;
  max-width: 291px;
  width: 100%;
}

.main-slider__three-facts-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-slider__three-facts-list li {
  position: relative;
  display: block;
  text-align: center;
  width: 160px;
  height: 120px;
  border-radius: 8px;
  padding: 15px;
  z-index: 1;
  min-width: 147px;

}

.main-slider__three-facts-list li:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.24);
}

.main-slider__three-facts-list li+li {
  margin-left: 20px;
}

.main-slider__three-facts-list li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 10px;
}

.main-slider__three-facts-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 42px;
  color: var(--endreox-white);
}

.main-slider__three-facts-list li h5 {
  font-size: 14px;
  color: var(--endreox-white);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 14px;
}

.main-slider__shape-1 {
  position: absolute;
  bottom: -265px;
  right: 100px;
  z-index: -1;
}

.main-slider__shape-1 img {
  width: auto;
}

.main-slider__shape-2 {
  position: absolute;
  bottom: -559px;
  left: -375px;
  background-color: #030721;
}

.main-slider__shape-2 img {
  mix-blend-mode: hard-light;
  width: auto;
}

/*--------------------------------------------------------------
# Main Slider Two
--------------------------------------------------------------*/
.main-slider-two {
  position: relative;
  display: block;
}

.main-slider-two .swiper-slide {
  position: relative;
  background-color: #58ABCF;
}

.main-slider-two .container {
  position: relative;
  padding-top: 243px;
  padding-bottom: 230px;
  z-index: 30;
}

.main-slider__img {
  position: absolute;
  top: 157px;
  right: 295px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider__img img {
  width: auto;
}

.swiper-slide-active .main-slider__img {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  visibility: visible;
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.main-slider__service-start {
  position: absolute;
  bottom: -33px;
  right: 45px;
  background-color: #CE9B4E;
  text-align: center;
  padding: 35px 45px 38px;
  border-radius: 50%;
  border-top-left-radius: 0;
}

.main-slider__service-start p {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  line-height: 25px;
  padding-bottom: 5px;
}

.main-slider__service-start h5 {
  font-size: 32px;
  color: #000000;
  font-weight: 700;
  line-height: 32px;
}

.main-slider-two__shape-1 {
  position: absolute;
  top: 105px;
  right: 0;
  mix-blend-mode: lighten;
  z-index: -1;
}

.main-slider__shape-1 img {
  width: auto;
}

.main-slider-two__content {
  position: relative;
  display: block;
  z-index: 1;
}

.main-slider-two__sub-title {
  font-size: 35px;
  color: var(--endreox-white);
  line-height: 35px;
  font-family: var(--endreox-font-four);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two .swiper-slide-active .main-slider-two__sub-title {
  visibility: visible;
  opacity: .50;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-two__title {
  position: relative;
  font-size: 65px;
  color: var(--endreox-white);
  font-weight: 900;
  line-height: 70px;
  margin-top: 12px;
  margin-bottom: 29px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__text-1 {
  font-size: 27px;
  color: var(--endreox-white);
  font-weight: 700;
  line-height: 37px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__text-2 {
  font-size: 20px;
  color: var(--endreox-white);
  font-weight: 400;
  line-height: 35px;
  margin-top: 16px;
  margin-bottom: 39px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__text-2 span {
  font-weight: 700;
  text-transform: uppercase;
}

.main-slider-two__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__btn {
  color: #010101;
  background-color: #CE9B4E;
  padding: 14px 45px 14px;
}

.main-slider-two__btn:hover {
  color: var(--endreox-base);
}

.main-slider-two__btn:before {
  background-color: var(--endreox-white);
}

.main-slider-two__shape-2 {
  position: absolute;
  top: -35px;
  left: -90px;
  border-radius: 50%;
  background-color: #1d61d7;
  opacity: 0.10;
  width: 222px;
  height: 222px;
  z-index: -1;
}

.main-slider-two .swiper-slide-active .main-slider-two__title,
.main-slider-two .swiper-slide-active .main-slider-two__text-1,
.main-slider-two .swiper-slide-active .main-slider-two__text-2,
.main-slider-two .swiper-slide-active .main-slider-two__btn-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-two__nav {
  position: absolute;
  top: 41%;
  left: 50%;
  z-index: 100;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  padding: 0 100px;
  height: 0;
  line-height: 0;
}

.main-slider-two__nav .swiper-button-next,
.main-slider-two__nav .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: #cccccc;
  background-color: transparent;
  border: 2px solid #cccccc;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 23px 0;
}

.main-slider-two__nav .swiper-button-next:hover,
.main-slider-two__nav .swiper-button-prev:hover {
  color: #fff;
  border: 2px solid #fff;
}

.main-slider-two__nav .swiper-button-next::after,
.main-slider-two__nav .swiper-button-prev::after {
  display: none;
}

.main-slider-two__nav .swiper-button-prev {
  margin-bottom: 20px;
}

.main-slider-two__nav .swiper-button-prev i {
  position: relative;
  display: inline-block;
}

/*--------------------------------------------------------------
# Main Slider Three
--------------------------------------------------------------*/
.main-slider-three {
  position: relative;
  display: block;
  margin-top: -33px;
  z-index: 1;
}

.main-slider-three .swiper-slide {
  position: relative;
  background-color: #d4d7e2;
}

.main-slider-three .container {
  position: relative;
  padding-top: 188px;
  padding-bottom: 210px;
  z-index: 30;
}

.image-layer-three {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.main-slider-three__content {
  position: relative;
  display: block;
  margin-right: -215px;
  margin-left: 50px;
}

.main-slider-three__content-left {
  margin-right: 0;
  margin-left: -50px;
}

.main-slider-three__content-inner {
  position: relative;
  display: block;
}

.main-slider-three__title-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three__tagline {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: #1168bc;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: var(--endreox-font-two);
  margin-left: 70px;
  z-index: 1;
}

.main-slider-three__tagline:before {
  position: absolute;
  left: -70px;
  top: 7px;
  width: 50px;
  height: 2px;
  background-color: #1168bc;
  content: "";
}

.main-slider-three__title {
  color: #0c1a3f;
  font-size: 75px;
  line-height: 70px;
  font-weight: 900;
  margin-top: 21px;
  margin-bottom: 19px;
  font-family: var(--endreox-font-five);
}

.main-slider-three__text {
  font-size: 22px;
  color: #0c1a3f;
  font-family: var(--endreox-font-five);
  line-height: 28px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three__btn-boxes {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 41px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three__btn-1 {
  font-size: 14px;
  padding-left: 35px;
  padding-right: 25px;
  padding-top: 14px;
  padding-bottom: 11px;
  border-radius: 4px;
}

.main-slider-three__btn-1 span {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  color: var(--endreox-white);
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  top: -1px;
  margin-left: 13px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.main-slider-three__btn-2 {
  font-size: 14px;
  padding-left: 35px;
  padding-right: 25px;
  padding-top: 14px;
  padding-bottom: 11px;
  border-radius: 4px;
  background-color: var(--endreox-black);
  margin-left: 20px;
}

.main-slider-three__btn-2 span {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  color: var(--endreox-white);
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  top: -1px;
  margin-left: 13px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.main-slider-three__btn-2::before {
  background-color: var(--endreox-base);
}

.main-slider-three .swiper-slide-active .main-slider-three__title-box,
.main-slider-three .swiper-slide-active .main-slider-three__text,
.main-slider-three .swiper-slide-active .main-slider-three__btn-boxes {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-three__nav {
  position: absolute;
  top: 42%;
  left: 50%;
  z-index: 100;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  padding: 0 70px;
  height: 0;
  line-height: 0;
}

.main-slider-three__nav .swiper-button-next,
.main-slider-three__nav .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: #616673;
  background-color: transparent;
  border: 2px solid #616673;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 23px 0;
}

.main-slider-three__nav .swiper-button-next:hover,
.main-slider-three__nav .swiper-button-prev:hover {
  color: #000;
  border: 2px solid #000;
}

.main-slider-three__nav .swiper-button-next::after,
.main-slider-three__nav .swiper-button-prev::after {
  display: none;
}

.main-slider-three__nav .swiper-button-prev {
  margin-bottom: 20px;
}

.main-slider-three__nav .swiper-button-prev i {
  position: relative;
  display: inline-block;
}

/*--------------------------------------------------------------
# Appointment One
--------------------------------------------------------------*/
.appointment-one {
  position: relative;
  display: block;
  background-color: #f3f4f8;
  z-index: 1;
  margin-top: 20px;
}

.appointment-one__inner {
  position: relative;
  display: block;
  background-color: #58ABCF;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-right: 75px;
  z-index: 1;
}

.appointment-one__inner:before {
  content: "";
  position: absolute;
  top: 0;
  left: -1000000px;
  right: 99%;
  bottom: 0;
  background-color: #58ABCF;
  z-index: -1;
}

.appointment-one__form {
  position: relative;
  display: block;
}

.appointment-one__list {
  position: relative;
  display: block;
}

.appointment-one__list li {
  position: relative;
  display: block;
  float: left;
  max-width: 203px;
  width: 100%;
  margin-bottom: 20px;
}

.appointment-one__list li+li {
  margin-left: 20px;
}

.appointment-one__input {
  position: relative;
  display: block;
}

.appointment-one__input input[type="text"],
.appointment-one__input input[type="email"] {
  height: 48px;
  width: 100%;
  background-color: var(--endreox-white);
  border: none;
  border-radius: 8px;
  outline: none;
  font-size: 18px;
  color: #474747;
  font-weight: 400;
  padding: 0 25px;
}

.appointment-one__btn {
  border: none;
  font-size: 14px;
  color: #1c1c1c;
  padding: 10px 27px 8px;
  background-color: #CE9B4E;
  border-radius: 8px;
  padding: 5px !important;
}

/*--------------------------------------------------------------
# About One
--------------------------------------------------------------*/
.about-one {
  position: relative;
  display: block;
  background-color: #f3f4f8;
  padding: 120px 0 120px;
  overflow: hidden;
  z-index: 1;
}

.about-one__img-one {
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: -1;
}

.about-one__img-one img {
  width: auto;
}

.about-one__left {
  position: relative;
  display: block;
  margin-right: 30px;
}

.about-one .section-title {
  margin-bottom: 26px;
}

.about-one__points-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 31px;
  margin-bottom: 53px;
}

.about-one__points-list {
  position: relative;
  display: block;
}

.about-one__points-list+.about-one__points-list {
  margin-left: 72px;
}

.about-one__points-box li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-one__points-box li+li {
  margin-top: 6px;
}

.about-one__points-icon {
  position: relative;
  display: inline-block;
  margin-right: 18px;
  top: 4px;
}

.about-one__points-icon span {
  font-size: 20px;
  color: var(--endreox-base);
}

.about-one__points-text {
  position: relative;
  display: block;
}

.about-one__points-text p {
  font-size: 20px;
  color: var(--endreox-black);
  line-height: 30px;
}

.about-one__bottom {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-one__call-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 386px;
  width: 100%;
  background-color: var(--endreox-white);
  border-radius: 7px;
  padding: 10px 30px 15px;
}

.about-one__call-box .icon {
  position: relative;
  display: inline-block;
  margin-right: 33px;
  margin-top: 10px;
}

.about-one__call-box .icon span {
  position: relative;
  display: inline-block;
  font-size: 49px;
  color: var(--endreox-base);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.about-one__call-box:hover span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.about-one__call-box .content {
  position: relative;
  display: block;
}

.about-one__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #242424;
  font-family: var(--endreox-font);
}

.about-one__number {
  font-size: 33px;
  font-weight: 700;
  line-height: 37px;
}

.about-one__number a {
  color: #58ABCF;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-one__number a:hover {
  color: var(--endreox-black);
}

.about-one__years-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 231px;
  background-color: #58ABCF;
  padding: 16px 30px 19px;
  border-radius: 7px;
}

.about-one__years {
  font-size: 47px;
  font-weight: 700;
  font-family: var(--endreox-font);
  color: var(--endreox-white);
}

.about-one__years-experience {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--endreox-white);
  margin-left: 23px;
}

/*--------------------------------------------------------------
# Services One
--------------------------------------------------------------*/
.services-one {
  position: relative;
  display: block;
  padding-top: 120px;
  z-index: 1;
}

.services-one__top {
  position: relative;
  display: block;
  margin-bottom: 69px;
}

.services-one__left {
  position: relative;
  display: block;
}

.services-one__left .section-title {
  margin-bottom: 0;
}

.services-one__right {
  position: relative;
  display: block;
  margin-left: 27px;
  margin-top: 14px;
}

.services-one__text {
  font-size: 20px;
  line-height: 30px;
  color: #494949;
}

.services-one__bottom {
  position: relative;
  display: block;
}

.services-one__carousel {
  position: relative;
  display: block;
}

.services-one__single {
  position: relative;
  display: block;
}

.services-one__img-box {
  position: relative;
  display: block;
  z-index: 1;
}

.services-one__img-box:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  content: "";
  background: #060706;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(2%, rgba(6, 7, 6, 0.728729)), color-stop(55%, rgba(6, 7, 6, 0)));
  background: linear-gradient(0deg, rgba(6, 7, 6, 0.728729) 2%, rgba(6, 7, 6, 0) 55%);
  z-index: 2;
}

.services-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  height: 332px;
  width: 100%;
}

.services-one__img::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: .30;
  content: "";
  z-index: 1;
}

.services-one__img::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(var(--endreox-black-rgb), 0.4);
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  z-index: 1;
}

.services-one__single:hover .services-one__img::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.services-one__img img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  height: 100%;
}

.services-one__single:hover .services-one__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.services-one__content {
  position: absolute;
  bottom: 15px;
  left: 64px;
  z-index: 2;
}

.services-one__content::before {
  content: "";
  position: absolute;
  top: -18px;
  left: -64px;
  width: 135px;
  bottom: -15px;
  background-color: var(--endreox-base);
  z-index: -1;
}

.services-one__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
}

.services-one__title a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-one__title a i {
  font-size: 38px;
  top: 2px;
  position: relative;
  margin-left: 36px;
}

.services-one__shape-1 {
  position: absolute;
  left: 80px;
  bottom: 101px;
  z-index: 1;
}

.services-one__shape-1 img {
  width: auto;
  opacity: .21;
}

.services-one__shape-3 {
  left: 208px;
}

.services-one__shape-2 {
  position: relative;
  display: block;
  height: 8px;
  background-color: #ffffff;
  margin-right: 83px;
}

/*--------------------------------------------------------------
# Services Two
--------------------------------------------------------------*/
.services-two {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 310px 0 100px;
  /* margin-top: -290px; */
}

.services-two__shape-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: .35;
}

.services-two__shape-1 img {
  width: auto;
}

.services-two__top {
  position: relative;
  display: block;
}

.services-two__top .section-title {
  margin-bottom: 59px;
}

.services-two__left {
  position: relative;
  display: block;
}

.services-two__left .section-title__title {
  color: var(--endreox-white);
}

.services-two__right {
  position: relative;
  display: block;
  margin-top: 80px;
}

.services-two__btn-box {
  position: relative;
  display: block;
  text-align: right;
}

.services-two__btn {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  text-transform: uppercase;
  color: var(--endreox-white);
  letter-spacing: 0.1em;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.services-two__btn:hover {
  color: var(--endreox-base);
}

.services-two__btn::before {
  content: "";
  position: absolute;
  top: -1px;
  right: 17px;
  width: 34px;
  height: 34px;
  background-color: #1578ee;
  border-radius: 50%;
  z-index: -1;
}

.services-two__btn i {
  margin-left: 47px;
  font-size: 32px;
}

.services-two__bottom {
  position: relative;
  display: block;
}

.services-two__carousel {
  position: relative;
  display: block;
}

.services-two__single {
  position: relative;
  display: block;
  text-align: center;
  background-color: #f3f4f7;
  border-radius: 8px;
  padding: 43px 35px 37px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.services-two__single:hover {
  background-color: #58ABCF;
}

.services-two__icon {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.services-two__icon::before {
  content: "";
  position: absolute;
  top: 17px;
  left: -19px;
  width: 50px;
  height: 50px;
  background-color: #cbe3ff;
  border-radius: 50%;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: -1;
}

.services-two__single:hover .services-two__icon::before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: .10;
}

.services-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: #58ABCF;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.services-two__single:hover .services-two__icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  color: #fff;
}

.services-two__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 9px;
  margin-top: 26px;
  text-transform: capitalize;
}

.services-two__title a {
  color: #1c242c;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__single:hover .services-two__title a {
  color: var(--endreox-white);
}

.services-two__text {
  color: #494949;
  line-height: 25px;
  font-size: 18px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__single:hover .services-two__text {
  color: var(--endreox-white);
}

.services-two__read-more {
  position: relative;
  display: block;
  margin-top: 13px;
}

.services-two__read-more a {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--endreox-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__read-more a:hover {
  color: var(--endreox-primary);
}

.services-two__carousel.owl-theme .owl-nav {
  position: relative;
  margin-top: 50px;
}

.services-two__carousel.owl-theme .owl-nav .owl-next {
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: var(--endreox-white);
  background-color: transparent;
  border: 2px solid var(--endreox-white);
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__carousel.owl-theme .owl-nav .owl-next span {
  font-weight: 900;
}

.services-two__carousel.owl-theme .owl-nav .owl-prev {
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: var(--endreox-white);
  background-color: transparent;
  border: 2px solid var(--endreox-white);
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__carousel.owl-theme .owl-nav .owl-prev span {
  font-weight: 900;
}

.services-two__carousel.owl-theme .owl-nav .owl-next {
  margin-left: 5px;
}

.services-two__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 22px;
}

.services-two__carousel.owl-theme .owl-nav .owl-next span,
.services-two__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.services-two__carousel.owl-theme .owl-nav .owl-next:hover,
.services-two__carousel.owl-theme .owl-nav .owl-prev:hover {
  border: 2px solid var(--endreox-base);
  color: var(--endreox-base);
}

/*--------------------------------------------------------------
# Project One
--------------------------------------------------------------*/
.project-one {
  position: relative;
  display: block;
  padding: 120px 00 90px;
  background-color: #e5e8f0;
  z-index: 1;
}

.project-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .10;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: darken;
  z-index: -1;
}

.project-one .section-title {
  margin-bottom: 29px;
}

.project-one .section-title__tagline {
  margin-left: 0;
}

.project-one .section-title__tagline:after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.project-one__filter-box {
  position: relative;
  display: block;
  margin-bottom: 52px;
}

.project-one__filter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.project-one__filter.style1 li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.project-one__filter.style1 li+li {
  margin-left: 46px;
}

.project-one__filter.style1 li .filter-text {
  position: relative;
  display: inline-block;
  color: #000000;
  font-size: 19px;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
}

.project-one__filter.style1 li .filter-text:before {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  content: "";
  opacity: 0;
  height: 1px;
  background-color: #000000;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.project-one__filter.style1 li:hover .filter-text:before {
  opacity: 1;
}

.project-one__filter.style1 li:hover .filter-text,
.project-one__filter.style1 li.active .filter-text {
  color: #000000;
}

.project-one__filter.style1 li.active .filter-text:before {
  opacity: 1;
}

.project-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.project-one__img {
  position: relative;
  display: block;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.project-one__img:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  border-radius: 5px;
  background: rgba(0, 122, 239, 0.95);
  transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  transition: -webkit-transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  background-position: center center;
  z-index: 1;
}

.project-one__single:hover .project-one__img:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.project-one__img img {
  width: 100%;
  border-radius: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.project-one__single:hover .project-one__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.project-one__content {
  position: absolute;
  left: 35px;
  bottom: 26px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 300ms ease 100ms;
  transition: all 300ms ease 100ms;
  z-index: 2;
}

.project-one__single:hover .project-one__content {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.project-one__sub-title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.project-one__title {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--endreox-font);
}

.project-one__title a {
  color: #fff;
}

.project-one__button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateX(20deg) translateX(100%);
  transform: perspective(400px) rotateX(20deg) translateX(100%);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
  z-index: 2;
}

.project-one__single:hover .project-one__button {
  opacity: 1.0;
  -webkit-transform: perspective(400px) rotateX(0deg) translateX(0%);
  transform: perspective(400px) rotateX(0deg) translateX(0%);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-one__button a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--endreox-white);
  border-radius: 5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.project-one__button a:hover {
  background-color: #CE9B4E;
}

.project-one__button a+a {
  margin-top: 5px;
}

.project-one__button a>img {
  width: auto;
}

/*--------------------------------------------------------------
# We Can Help
--------------------------------------------------------------*/
.we-can-help {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.we-can-help__left {
  position: relative;
  display: block;
}

.we-can-help__left .section-title {
  margin-bottom: 29px;
}

.we-can-help__btn {
  margin-top: 32px;
}

.we-can-help__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 34px;
}

.we-can-help__progress {
  position: relative;
  display: block;
  width: 100%;
}

.we-can-help__progress-single {
  position: relative;
  display: block;
}

.we-can-help__progress-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 13px;
  font-family: var(--endreox-font);
}

.we-can-help__progress .bar {
  position: relative;
  width: 100%;
  height: 10px;
  background: #58ABCF;
  border-radius: 4px;
  margin-bottom: 30px;
}

.we-can-help__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 10px;
  border-radius: 4px;
  background: #CE9B4E;
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.we-can-help__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 25px;
  line-height: 26px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  opacity: 0;
  color: var(--endreox-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.we-can-help__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.we-can-help__progress .bar.marb-0 {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Counter One
--------------------------------------------------------------*/
.counter-one {
  position: relative;
  display: block;
  background-color: #58ABCF;
  padding: 70px 0 70px;
}

.counter-one__left {
  position: relative;
  display: block;
  margin-left: -54px;
  margin-right: 22px;
}

.counter-one__year-box {
  position: relative;
  display: block;
  border: 5px solid #9D554C;
  padding: 11px 41px 31px;
  border-right: none;
}

.counter-one__year-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 63px;
  width: 5px;
  background-color: #9D554C;
}

.counter-one__year-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 63px;
  width: 5px;
  background-color: #9D554C;
}

.counter-one__year {
  font-size: 26px;
  font-weight: 900;
  line-height: 36px;
  text-transform: uppercase;
  font-family: var(--endreox-font-two);
  color: var(--endreox-white);
  padding-left: 8px;
}

.counter-one__year span {
  font-size: 150px;
  line-height: 160px;
  color: #CE9B4E;
  position: relative;
}

.counter-one__year-title {
  font-size: 31px;
  line-height: 43px;
  color: var(--endreox-white);
  font-weight: 300;
  margin-top: 3px;
}

.counter-one__year-title span {
  font-size: 34px;
  font-weight: 700;
  line-height: 45px;
}

.counter-one__right {
  position: relative;
  display: block;
  margin-top: 53px;
}

.counter-one__box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.counter-one__single {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.counter-one__single:before {
  position: absolute;
  top: 3px;
  left: 0;
  bottom: -3px;
  content: "";
  width: 1px;
  background-color: rgba(var(--endreox-white-rgb), 0.2);
}

.counter-one__single:first-child:before {
  display: none;
}

.counter-one__icon {
  position: relative;
  display: inline-block;
}

.counter-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 66px;
  color: #ffffff;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.counter-one__single:hover .counter-one__icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.counter-one__content-box {
  position: relative;
  display: block;
  margin-top: 5px;
}

.counter-one__single h3 {
  font-size: 58px;
  font-weight: 900;
  color: #CE9B4E;
  font-family: var(--endreox-font-two) !important;
  line-height: 67px !important;
}

.counter-one__letter {
  font-size: 58px;
  font-weight: 900;
  color: #CE9B4E;
  font-family: var(--endreox-font-two) !important;
  vertical-align: middle;
  top: -3px;
  position: relative;
}

.counter-one__text {
  font-size: 20px;
  line-height: 23px;
  color: var(--endreox-white);
}

/*--------------------------------------------------------------
# Why Choose One
--------------------------------------------------------------*/
.why-choose-one {
  position: relative;
  display: block;
  padding: 120px 0 98px;
  background-color: #f2f6f9;
}

.why-choose-one__top {
  position: relative;
  display: block;
  padding-bottom: 94px;
}

.why-choose-one__left {
  position: relative;
  display: block;
  margin-right: 40px;
}

.why-choose-one__img-box {
  position: relative;
  display: block;
}

.why-choose-one__img {
  position: relative;
  display: block;
}

.why-choose-one__img img {
  width: 538px;
  border-radius: 8px;
  height: 400px;

}

.why-choose-one__count-box {
  position: absolute;
  background-color: var(--endreox-primary);
  text-align: center;
  padding: 36px 36px 24px;
  border-radius: 8px;
  left: 30px;
  bottom: -34px;
}

.why-choose-one__count-box .icon {
  position: relative;
  display: inline-block;
}

.why-choose-one__count-box .icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: #ffffff;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.why-choose-one__count-box:hover .icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.why-choose-one__content-box {
  position: relative;
  display: block;
  margin-top: 9px;
}

.why-choose-one__content-box h3 {
  font-size: 45px;
  font-weight: 700;
  color: var(--endreox-white);
  font-family: var(--endreox-font) !important;
  line-height: 45px !important;
}

.why-choose-one__letter {
  font-size: 27px;
  font-weight: 700;
  color: var(--endreox-white);
  font-family: var(--endreox-font) !important;
  line-height: 42px !important;
  position: relative;
  top: -3px;
  padding-left: 3px;
}

.why-choose-one__count-text {
  font-size: 20px;
  line-height: 26px;
  color: var(--endreox-white);
  font-weight: 500;
}

.why-choose-one__count-box .odometer-formatting-mark {
  display: none;
}

.why-choose-one__right {
  position: relative;
  display: block;
}

.why-choose-one__right .section-title__title {
  color: #58ABCF;
}

.why-choose-one__right .section-title {
  margin-bottom: 21px;
}

.why-choose-one__text {
  position: relative;
  display: block;
}

.why-choose-one__points {
  position: relative;
  display: block;
  margin-top: 35px;
}

.why-choose-one__points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.why-choose-one__points li+li {
  margin-top: 8px;
}

.why-choose-one__points li .icon {
  position: relative;
  display: inline-block;
  top: -1px;
}

.why-choose-one__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #848688;
}

.why-choose-one__points li .text {
  position: relative;
  display: block;
}

.why-choose-one__points li .text p {
  font-size: 19px;
  font-weight: 500;
  line-height: 29px;
  color: #202743;
  margin-left: 18px;
}

.why-choose-one__bottom {
  position: relative;
  display: block;
}

.why-choose-one__single {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  text-align: center;
  margin-bottom: 16px;
}

.advantage_section .advantage_heading h2,
.advantage_section .advantage_heading h1 {
  padding-bottom: 15px;
  color: black;
}

.advantage_section .advantage_heading h1 b {

  color: #009cde;
}

.advantage_section .advantage_heading p {
  margin-bottom: 3rem;
  font-size: 18px;
}


@media (min-width: 992px) {
  .why-choose-one__bottom .col-5 {
    width: 20%;
  }
}

.why-choose-one__single .icon {
  position: relative;
  display: inline-block;
  top: 10px;
}

.why-choose-one__single .icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--endreox-primary);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 15px;
}

.why-choose-one__single:hover .icon span {
  /* -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; */
  background-color: #009cde;

  border-radius: 50%;
  color: white;
}

.why-choose-one__content {
  position: relative;
  display: block;
  margin-left: 22px;
}

.why-choose-one__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  font-family: var(--endreox-font);
  padding-top: 30px;
}

.why-choose-one__title a {
  color: black;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.why-choose-one__title a:hover {
  color: var(--endreox-base);
}

.why-choose-one__text-2 {
  font-size: 17px;
  line-height: 26px;
  margin-top: 9px;
}

/*--------------------------------------------------------------
# Testimonial One
--------------------------------------------------------------*/
.testimonial-one {
  position: relative;
  display: block;
  background-color: #0a3974;
  padding: 120px 0 120px;
  overflow: hidden;
  z-index: 1;
}

.testimonial-one__shape-1 {
  position: absolute;
  left: 95px;
  top: 20px;
  z-index: -1;
}

.testimonial-one__shape-1 img {
  width: auto;
  opacity: .15;
}

.testimonial-one__left {
  position: relative;
  display: block;
}

.testimonial-one__left .section-title {
  margin-bottom: 32px;
}

.testimonial-one__left .section-title__title {
  color: var(--endreox-white);
}

.testimonial-one__text {
  font-size: 20px;
  line-height: 28px;
  color: var(--endreox-white);
}

.testimonial-one__right {
  position: relative;
  display: block;
  margin-left: 80px;
}

.testimonial-one__carousel {
  position: relative;
  display: block;
}

.testimonial-one__single {
  position: relative;
  display: block;
}

.testimonial-one__inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--endreox-primary);
  padding: 53px 48px 50px;
  border-radius: 15px;
}

.testimonial-one__inner:before {
  content: "";
  position: absolute;
  left: 95px;
  bottom: -10px;
  width: 40px;
  height: 10px;
  border-top: 11px solid var(--endreox-primary);
  border-left: 22.5px solid transparent;
  border-right: 22.5px solid transparent;
}

.testimonial-one__client-img {
  position: relative;
  display: block;
  margin-top: 6px;
}

.testimonial-one__client-img img {
  width: auto !important;
  border-radius: 50%;
}

.testimonial-one__client-info {
  position: relative;
  display: block;
  margin-left: 39px;
}

.testimonial-one__client-text {
  font-size: 15px;
  line-height: 30px;
  color: var(--endreox-white);
}

.testimonial-one__client-rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 26px;
}

.testimonial-one__client-rating i {
  font-size: 22px;
  color: #ffc85c;
}

.testimonial-one__client-rating i+i {
  margin-left: 10px;
}

.testimonial-one__client-content {
  position: relative;
  display: block;
  padding-top: 22px;
  margin-left: 78px;
}

.testimonial-one__client-content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  font-family: var(--endreox-font);
  color: var(--endreox-white);
}

.testimonial-one__client-content span {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  text-transform: uppercase;
  color: var(--endreox-white);
  letter-spacing: 0.1em;
}

.testimonial-one__carousel.owl-theme .owl-nav {
  position: absolute;
  right: 0;
  bottom: -4px;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-next {
  height: 43px;
  width: 43px;
  line-height: 43px;
  border-radius: 50%;
  color: #000000;
  background-color: #CE9B4E;
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-next span {
  font-weight: 900;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-prev {
  height: 43px;
  width: 43px;
  line-height: 43px;
  border-radius: 50%;
  color: #000000;
  background-color: #CE9B4E;
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-prev span {
  font-weight: 900;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-next {
  margin-left: 5px;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 15px;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-next span,
.testimonial-one__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-one__carousel.owl-theme .owl-nav .owl-next:hover,
.testimonial-one__carousel.owl-theme .owl-nav .owl-prev:hover {
  background-color: var(--endreox-white);
  color: var(--endreox-black);
}

/*--------------------------------------------------------------
# Blog One
--------------------------------------------------------------*/
.blog-one {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 120px 0 90px;
}

.blog-one .section-title__tagline {
  margin-left: 0;
}

.blog-one .section-title__tagline::after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.blog-one .section-title__title {
  color: #58ABCF;
}

.blog-one__single {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.blog-one__img-box {
  position: relative;
  display: block;
  z-index: 1;
}

.blog-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 7px;
  z-index: 1;
}

.blog-one__img img {
  width: 100%;
  border-radius: 7px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 250px;
}

.blog-one__single:hover .blog-one__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.blog-one__single.active .blog-one__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.blog-one__content-box {
  position: relative;
  display: block;
  background-color: #fff;
  padding-top: 34px;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 2;
}

.blog-one__single:hover .blog-one__content-box {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.blog-one__single.active .blog-one__content-box {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.blog-one__date {
  position: relative;
  display: block;
  margin-left: 40px;
}

.blog-one__date p {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  font-family: var(--endreox-font-two);
  letter-spacing: 0.1em;
  color: #6e6e6e;
}

.blog-one__date p span {
  position: relative;
  display: inline-block;
  color: var(--endreox-primary);
  margin-right: 25px;
}

.blog-one__date p span::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -40px;
  height: 2px;
  width: 30px;
  background-color: var(--endreox-primary);
}

.blog-one__date p span::after {
  position: absolute;
  top: 6px;
  right: -12px;
  bottom: 6px;
  width: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.blog-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 9px;
}

.blog-one__title a {
  color: #242424;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__title a:hover {
  color: var(--endreox-base);
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: #ffffff;
  z-index: 1;
}

.site-footer__shape-1 {
  position: absolute;
  bottom: 0;
  right: 100px;
  mix-blend-mode: hard-light;
  z-index: -1;
}

.site-footer__shape-1 img {
  width: auto;
}

.site-footer__top {
  position: relative;
  display: block;
  background-color: #58abcf;
  padding: 24px 0 22px;
  border: 1px solid #202743;
  z-index: 1;
}

.site-footer__inner {
  position: relative;
  display: block;
  padding: 0 80px 0;
}

.site-footer__contact-info {
  position: relative;
  display: block;
}

.site-footer__contact-points {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.site-footer__contact-points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-footer__contact-points li:before {
  position: absolute;
  top: -25px;
  bottom: -23px;
  right: -50px;
  width: 1px;
  background-color: #1e2739;
  content: "";
}

.site-footer__contact-points li:last-child:before {
  display: none;
}

.site-footer__contact-points li .icon {
  position: relative;
  display: inline-block;
}

.site-footer__contact-points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 37px;
  color: var(--endreox-base);
  font-weight: 600;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.site-footer__contact-points li:hover span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  color: var(--endreox-white);
}

.site-footer__contact-points li .content {
  position: relative;
  display: block;
  /* margin-left: 27px; */
}

.site-footer__contact-points li .content h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  color: var(--endreox-white);
  font-family: var(--endreox-font);
}

.site-footer__contact-points li .content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--endreox-white);
  font-family: var(--endreox-font);
}

.site-footer__contact-points li .content a {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--endreox-white);
  font-family: var(--endreox-font);
}

.site-footer__contact-points li:last-child .content a {
  font-size: 22px;
  font-weight: 500;
  padding-top: 2px;
}

.site-footer__middle {
  position: relative;
  display: block;
  padding-top: 62px;
}

.footer-widget__img-box {
  position: relative;
  display: block;
  margin-left: 15px;
  margin-right: 74px;
  z-index: 2;
  margin-top: -17px;
}

.footer-widget__img {
  position: relative;
  display: block;
  z-index: 1;
}

.footer-widget__img img {
  width: 100%;
}

.footer-widget__useful-links {
  position: relative;
  display: block;
}

.footer-widget__title-box {
  position: relative;
  display: block;
  margin-bottom: 17px;
}

.footer-widget__title {
  font-size: 21px;
  font-weight: 700;
  line-height: 31px;
  color: #58abcf;
}

.footer-widget__useful-links-list {
  position: relative;
  display: block;
}

.footer-widget__useful-links-list li+li {
  margin-top: 6px;
}

.footer-widget__useful-links-list li a {
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  color: #242424;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.footer-widget__useful-links-list li a:hover {
  color: #58abcf;
  font-weight: bold;
}


.footer-widget__useful-links-list1 {
  position: relative;
  display: block;
}

.footer-widget__useful-links-list1 li+li {
  margin-top: 6px;

}

.footer-widget__useful-links-list1 li a {
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  color: #242424;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.footer-widget__useful-links-list1 li a:hover {
  color: #58abcf;
  font-weight: bold;
}



.footer-widget__services {
  position: relative;
  display: block;
}

.footer-widget__about {
  position: relative;
  display: block;
  margin-top: 56px;
  margin-left: 60px;
}

.footer-widget__logo {
  position: relative;
  display: block;
}

.site-footer__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 35px;
}

.site-footer__social a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #58abcf;
  border-radius: 50%;
  color: #58abcf;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__social a:hover {
  border: 1px solid var(--endreox-base);
  color: var(--endreox-base);
}

.site-footer__social a+a {
  margin-left: 13px;
}

.site-footer__bottom {
  position: relative;
  display: block;
}

.site-footer__bottom-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--endreox-white-rgb), 0.1);
  padding: 20px 0 30px;
}

.site-footer__bottom-text {
  color: #a0a5b1;
}

.site-footer__bottom-text a {
  color: #a0a5b1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-text a:hover {
  color: var(--endreox-base);
}

.site-footer__bottom-text-two {
  position: relative;
  display: block;
}

.site-footer__bottom-text-two a {
  color: #a0a5b1;
  opacity: .80;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-text-two a:hover {
  color: var(--endreox-base);
}

/*--------------------------------------------------------------
# Experience And Rating
--------------------------------------------------------------*/
.experience-and-rating {
  position: relative;
  display: block;
}

.experience-and-rating .row {
  --bs-gutter-x: 0px;
}

.experience-and-rating__left {
  position: relative;
  display: block;
  background-color: #CE9B4E;
  padding-bottom: 42px;
  z-index: 1;
}

.experience-and-rating__left:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1000000px;
  right: 0;
  top: -19px;
  background-color: #CE9B4E;
  z-index: -1;
}

.experience-and-rating__experience {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.experience-and-rating__experience-year {
  position: relative;
  display: block;
}

.experience-and-rating__experience-year h2 {
  font-size: 135px;
  font-weight: 800;
  line-height: 135px;
  font-family: var(--endreox-font);
}

.experience-and-rating__experience-year h5 {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 10px;
}

.experience-and-rating__experience-text-box {
  position: relative;
  display: block;
  max-width: 260px;
  margin-left: 40px;
}

.experience-and-rating__experience-text-box p {
  font-size: 19px;
  font-weight: 500;
  color: var(--endreox-black);
}

.experience-and-rating__right {
  position: relative;
  display: block;
  background-color: #1d61d7;
  padding: 55px 0 63px;
  padding-left: 70px;
  z-index: 1;
}

.experience-and-rating__right:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: -100000px;
  top: 0;
  background-color: #1d61d7;
  z-index: -1;
}

.experience-and-rating__rating-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.experience-and-rating__rating-icon {
  position: relative;
  display: block;
}

.experience-and-rating__rating-icon span {
  position: relative;
  display: inline-block;
  font-size: 65px;
  color: var(--endreox-white);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.experience-and-rating__rating-box:hover .experience-and-rating__rating-icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.experience-and-rating__rating-text {
  font-size: 23px;
  color: var(--endreox-white);
  margin-left: 40px;
}

/*--------------------------------------------------------------
# About Two
--------------------------------------------------------------*/
.about-two {
  position: relative;
  display: block;
  padding: 120px 0 146px;
  background-color: #f9fafe;
}

.about-two::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #f1f1f5;
}

.about-two__left {
  position: relative;
  display: block;
  margin-right: 115px;
}

.about-two__img-box {
  position: relative;
  display: block;
}

.about-two__img {
  position: relative;
  display: block;
}

.about-two__img img {
  width: 100%;
  border-radius: 8px;
}

.about-two__count-box {
  position: absolute;
  right: -70px;
  bottom: -38px;
  background-color: var(--endreox-primary);
  max-width: 221px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  padding-bottom: 26px;
}

.about-two__count-box h3 {
  font-size: 62px;
  font-weight: 700;
  line-height: 62px !important;
  font-family: var(--endreox-font) !important;
  color: var(--endreox-white);
  text-transform: uppercase;
}

.about-two__letter {
  font-size: 62px;
  font-weight: 700;
  line-height: 72px !important;
  font-family: var(--endreox-font) !important;
  color: var(--endreox-white);
  text-transform: uppercase;
  top: 18px;
  position: relative;
}

.about-two__plus {
  font-size: 40px;
  font-weight: 700;
  line-height: 46px !important;
  font-family: var(--endreox-font) !important;
  color: var(--endreox-white);
  position: relative;
  top: -1px;
}

.about-two__count-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: var(--endreox-white);
}

.about-two__right {
  position: relative;
  display: block;
}

.about-two__right .section-title {
  margin-bottom: 30px;
}

.about-two__right .section-title__title {
  color: #58ABCF;
}

.about-two__text-1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #242424;
}

.about-two__text-2 {
  color: #494949;
  margin-top: 20px;
  margin-bottom: 44px;
}

.about-two__points-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-two__points {
  position: relative;
  display: block;
}

.about-two__points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-two__points li+li {
  margin-top: 10px;
}

.about-two__points li .icon {
  position: relative;
  display: inline-block;
  top: 2px;
}

.about-two__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--endreox-primary);
}

.about-two__points li .text {
  position: relative;
  display: block;
}

.about-two__points li .text p {
  font-weight: 700;
  color: #2a2a2a;
  margin-left: 15px;
  font-family: var(--endreox-font-two);
}

.about-two__points-2 {
  margin-left: 75px;
}

/*--------------------------------------------------------------
# Services Three
--------------------------------------------------------------*/
.services-three {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.services-three .container {
  max-width: 1308px;
}

.services-three__top {
  position: relative;
  display: block;
}

.services-three__left {
  position: relative;
  display: block;
}

.services-three__left .section-title__title {
  color: #58ABCF;
}

.services-three__right {
  position: relative;
  display: block;
}

.services-three__text {
  color: #494949;
}

.services-three__middle {
  position: relative;
  display: block;
}

.services-three__carousel {
  position: relative;
  display: block;
}

.services-three__single {
  position: relative;
  display: block;
}

.services-three__img-box {
  position: relative;
  display: block;
}

.services-three__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.services-three__img::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(0, 122, 239, 0.9);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  z-index: 1;
}

.services-three__single:hover .services-three__img::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.services-three__img img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.services-three__single:hover .services-three__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.services-three__content-box {
  position: relative;
  display: block;
  background-color: #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 28px 33px 36px;
  z-index: 1;
}

.services-three__content-box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  top: 0;
  border: 1px solid #ddddd5;
  background-color: var(--endreox-white);
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: -1;
}

.services-three__title {
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  margin-bottom: 12px;
}

.services-three__title a {
  color: #10250f;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-three__title a:hover {
  color: var(--endreox-base);
}

.services-three__read-more {
  position: relative;
  display: block;
}

.services-three__read-more a {
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--endreox-base);
  font-family: var(--endreox-font-two);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-three__read-more a:hover {
  color: var(--endreox-black);
}

.services-three__carousel.owl-theme .owl-nav {
  position: relative;
  margin-top: 60px;
}

.services-three__carousel.owl-theme .owl-nav .owl-next {
  position: relative;
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: #a8b0b7;
  background-color: transparent;
  border: 2px solid #a8b0b7;
  font-size: 18px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-three__carousel.owl-theme .owl-nav .owl-next::before {
  content: "";
  position: absolute;
  top: 18px;
  left: 62px;
  width: 71px;
  height: 1px;
  background-color: #d3d7da;
}

.services-three__carousel.owl-theme .owl-nav .owl-next span {
  font-weight: 900;
}

.services-three__carousel.owl-theme .owl-nav .owl-prev {
  position: relative;
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: #a8b0b7;
  background-color: transparent;
  border: 2px solid #a8b0b7;
  font-size: 18px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-three__carousel.owl-theme .owl-nav .owl-prev::before {
  content: "";
  position: absolute;
  top: 18px;
  right: 62px;
  width: 71px;
  height: 1px;
  background-color: #d3d7da;
}

.services-three__carousel.owl-theme .owl-nav .owl-prev span {
  font-weight: 900;
}

.services-three__carousel.owl-theme .owl-nav .owl-next {
  margin-left: 5px;
}

.services-three__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 17px;
}

.services-three__carousel.owl-theme .owl-nav .owl-next span,
.services-three__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.services-three__carousel.owl-theme .owl-nav .owl-next:hover,
.services-three__carousel.owl-theme .owl-nav .owl-prev:hover {
  border: 2px solid var(--endreox-base);
  color: var(--endreox-base);
}

.services-three__bottom {
  position: relative;
  display: block;
  margin-top: 77px;
}

.additional-services__left {
  position: relative;
  display: block;
  margin-bottom: 22px;
}

.additional-services__title {
  font-size: 30px;
  font-weight: 900;
  line-height: 36px;
  color: #58ABCF;
}

.additional-services__single {
  position: relative;
  display: block;
  margin-bottom: 22px;
}

.additional-services__text {
  font-weight: 600;
  color: #242424;
}

/*--------------------------------------------------------------
# Counter Two
--------------------------------------------------------------*/
.counter-two {
  position: relative;
  display: block;
  background-color: #58ABCF;
  padding: 329px 0 120px;
  margin-top: -209px;
}

/*--------------------------------------------------------------
# Project Two
--------------------------------------------------------------*/
.project-two {
  position: relative;
  display: block;
  background-color: #e5e8f0;
  padding: 120px 0 217px;
  z-index: 1;
}

.project-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .10;
  mix-blend-mode: darken;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.project-two__left {
  position: relative;
  display: block;
}

.project-two__right {
  position: relative;
  display: block;
  margin-right: -300px;
}

.project-two__carousel {
  position: relative;
  display: block;
}

.project-two__single {
  position: relative;
  display: block;
}

.project-two__img {
  position: relative;
  display: block;
  border-radius: 6px;
  overflow: hidden;
}

.project-two__img:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  border-radius: 6px;
  background: rgba(0, 122, 239, 0.9);
  transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  transition: -webkit-transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  background-position: center center;
  z-index: 1;
}

.project-two__single:hover .project-two__img:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.project-two__img img {
  width: 100%;
  border-radius: 6px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.project-two__single:hover .project-two__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.project-two__content {
  position: absolute;
  left: 35px;
  bottom: 26px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 300ms ease 100ms;
  transition: all 300ms ease 100ms;
  z-index: 2;
}

.project-two__single:hover .project-two__content {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.project-two__sub-title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.project-two__title {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--endreox-font);
}

.project-two__title a {
  color: #fff;
}

.project-two__button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateX(20deg) translateX(100%);
  transform: perspective(400px) rotateX(20deg) translateX(100%);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
  z-index: 2;
}

.project-two__single:hover .project-two__button {
  opacity: 1.0;
  -webkit-transform: perspective(400px) rotateX(0deg) translateX(0%);
  transform: perspective(400px) rotateX(0deg) translateX(0%);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-two__button a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--endreox-white);
  border-radius: 5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.project-two__button a:hover {
  background-color: #CE9B4E;
}

.project-two__button a+a {
  margin-top: 5px;
}

.project-two__button a>img {
  width: auto !important;
}

.project-two__carousel.owl-theme .owl-nav {
  position: absolute;
  left: -270px;
  bottom: 122px;
}

.project-two__carousel.owl-theme .owl-nav .owl-next {
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: var(--endreox-black);
  background-color: transparent;
  border: 2px solid var(--endreox-black);
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__carousel.owl-theme .owl-nav .owl-next span {
  font-weight: 900;
}

.project-two__carousel.owl-theme .owl-nav .owl-prev {
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: var(--endreox-black);
  background-color: transparent;
  border: 2px solid var(--endreox-black);
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__carousel.owl-theme .owl-nav .owl-prev span {
  font-weight: 900;
}

.project-two__carousel.owl-theme .owl-nav .owl-next {
  margin-left: 5px;
}

.project-two__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 22px;
}

.project-two__carousel.owl-theme .owl-nav .owl-next span,
.project-two__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.project-two__carousel.owl-theme .owl-nav .owl-next:hover,
.project-two__carousel.owl-theme .owl-nav .owl-prev:hover {
  border: 2px solid var(--endreox-base);
  color: var(--endreox-base);
}

/*--------------------------------------------------------------
# Contact Bar
--------------------------------------------------------------*/
.contact-bar {
  position: relative;
  display: block;
  margin-top: -100px;
  z-index: 1;
}

.contact-bar__inne {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #0b1953;
  margin-right: -100px;
  padding-left: 55px;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 1;
}

.contact-bar__inne:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -100000px;
  content: "";
  background-color: #0b1953;
  z-index: -1;
}

.contact-bar__left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contact-bar__left-icon {
  position: relative;
  display: block;
}

.contact-bar__left-icon span {
  position: relative;
  display: inline-block;
  font-size: 65px;
  color: #f1d551;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.contact-bar__left:hover .contact-bar__left-icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.contact-bar__left-content {
  margin-left: 40px;
  position: relative;
  top: -9px;
}

.contact-bar__title {
  font-size: 40px;
  color: var(--endreox-white);
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 10px;
}

.contact-bar__text {
  font-size: 20px;
  color: var(--endreox-white);
}

.contact-bar__right {
  position: relative;
  display: block;
}

.contact-bar__call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-bar__call-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 95px;
  width: 95px;
  border: 1px dashed #dac351;
  border-radius: 50%;
}

.contact-bar__call-icon img {
  width: auto;
}

.contact-bar__call-content {
  margin-left: 30px;
}

.contact-bar__call-number {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 6px;
}

.contact-bar__call-number a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-bar__call-number a:hover {
  color: var(--endreox-base);
}

.contact-bar__call-time {
  font-size: 20px;
  color: var(--endreox-white);
}

/*--------------------------------------------------------------
# Team One
--------------------------------------------------------------*/
.team-one {
  position: relative;
  display: block;
  background-color: #f2f3f7;
  padding: 188px 0 100px;
  margin-top: -95px;
}

.team-one .row {
  --bs-gutter-x: 10px;
}

.team-one__top {
  position: relative;
  display: block;
}

.team-one__left {
  position: relative;
  display: block;
}

.team-one__left .section-title__title {
  color: #58ABCF;
}

.team-one__right {
  position: relative;
  display: block;
  margin-left: 115px;
}

.team-one__text {
  color: #494949;
}

.team-one__bottom {
  position: relative;
  display: block;
  padding-bottom: 70px;
  border-bottom: 1px solid #ced0d5;
}

.team-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.team-one__img-box {
  position: relative;
  display: block;
  overflow: hidden;
  padding-right: 20px;
}

.team-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #000000;
  border-radius: 5px;
}

.team-one__img img {
  width: 100%;
  border-radius: 5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.team-one__single:hover .team-one__img img {
  opacity: 0.30;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
}

.team-one__title-box {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  bottom: 0;
  left: 20px;
  right: 0;
  background-color: var(--endreox-primary);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 13px 32px 13px;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.team-one__single:hover .team-one__title-box {
  visibility: hidden;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
}

.team-one__title-box-left {
  position: relative;
  display: block;
  margin-right: 7px;
}

.team-one__title-box-name {
  font-size: 21px;
  font-weight: 700;
  line-height: 31px;
}

.team-one__title-box-name a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__title-box-sub-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--endreox-white);
  font-family: var(--endreox-font-two);
}

.team-one__title-box-btn {
  position: relative;
  display: block;
  top: -3px;
}

.team-one__content-box {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  background-color: var(--endreox-primary);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 35px 32px 35px;
  -webkit-transform: translateY(-66%);
  transform: translateY(-66%);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

.team-one__single:hover .team-one__content-box {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
}

.team-one__content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.team-one__team-info {
  position: relative;
  display: block;
  margin-right: 7px;
}

.team-one__name {
  font-size: 21px;
  font-weight: 700;
  line-height: 31px;
}

.team-one__name a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__name a:hover {
  color: var(--endreox-base);
}

.team-one__sub-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--endreox-white);
  font-family: var(--endreox-font-two);
}

.team-one__btn {
  position: relative;
  display: block;
  top: -3px;
}

.team-one__btn a img {
  width: 100%;
}

.team-one__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}

.team-one__social a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-size: 16px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__social a:hover {
  background-color: var(--endreox-white);
  color: var(--endreox-base);
}

.team-one__social a+a {
  margin-left: 11px;
}

/*--------------------------------------------------------------
# Coupons
--------------------------------------------------------------*/
.coupons {
  position: relative;
  display: block;
  background-color: #f2f3f7;
  padding: 0 0 90px;
}

.coupons__left {
  position: relative;
  display: block;
  margin-right: 70px;
  margin-bottom: 16px;
}

.coupons__left .section-title {
  margin-bottom: 12px;
}

.coupons__left .section-title__title {
  color: #58ABCF;
}

.coupons__single {
  position: relative;
  display: block;
  background-color: var(--endreox-white);
  padding: 36px 20px 36px;
  border: 3px dashed #000000;
  margin-bottom: 30px;
  z-index: 1;
}

.coupons__shape-1 {
  position: absolute;
  bottom: -13px;
  right: 55px;
}

.coupons__shape-1 img {
  width: auto;
}

.coupons__single-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.coupons__offer-amount-box {
  position: relative;
  display: block;
  max-width: 152px;
  width: 100%;
}

.coupons__offer-amount {
  position: relative;
  display: block;
  background-color: #58ABCF;
  padding: 15px 20px 10px;
}

.coupons__offer-amount h2 {
  font-size: 60px;
  color: var(--endreox-white);
  font-weight: 700;
  font-family: var(--endreox-font-three);
  line-height: 40px;
  letter-spacing: 0.03em;
  text-align: right;
}

.coupons__offer-amount h2 sup {
  font-size: 36px;
  left: 17px;
}

.coupons__offer-amount h2 span {
  font-size: 40px;
  line-height: 40px;
}

.coupons__offer-qr-code {
  position: relative;
  display: block;
}

.coupons__offer-qr-code img {
  width: auto;
}

.coupons__offer-content {
  margin-left: 18px;
}

.coupons__offer-title-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.coupons__offer-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #092942;
}

.coupons__offer-sub-title {
  font-size: 8px;
  color: #767676;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 10px;
  font-family: var(--endreox-font-three);
}

.coupons__offer-text {
  font-size: 13px;
  color: #404c60;
  line-height: 17px;
  font-weight: 500;
}

.coupons__single-two .coupons__offer-amount {
  background-color: var(--endreox-primary);
  padding: 15px 15px 10px;
}

.coupons__single-two .coupons__offer-amount h2 {
  font-size: 47px;
}

.coupons__single-two .coupons__offer-amount h2 sup {
  left: 13px;
  top: -7px;
}

/*--------------------------------------------------------------
# Testimonial Two
--------------------------------------------------------------*/
.testimonial-two {
  position: relative;
  display: block;
  padding: 88px 0 100px;
  z-index: 1;
}

.testimonial-two__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.testimonial-two__content-box {
  position: relative;
  display: block;
}

.testimonial-two__title-box {
  position: relative;
  display: inline-block;
  margin-left: 33px;
  margin-bottom: 32px;
}

.testimonial-two__title-box:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -33px;
  width: 20px;
  height: 2px;
  background-color: #2b1f48;
}

.testimonial-two__title-box h5 {
  font-size: 14px;
  color: #2b1f48;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 14px;
}

.testimonial-two__carousel {
  position: relative;
  display: block;
}

.testimonial-two__single {
  position: relative;
  display: block;
}

.testimonial-two__content {
  position: relative;
  display: block;
  background-color: var(--endreox-white);
  border-radius: 10px;
  padding: 48px 40px 63px;
  z-index: 1;
}

.testimonial-two__quote {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.testimonial-two__quote i {
  font-size: 33px;
  color: #11aded;
  position: relative;
  display: inline-block;
}

.testimonial-two__text {
  font-size: 20px;
  color: #2e2e2e;
  font-weight: 500;
  font-style: italic;
}

.testimonial-two__shape-1 {
  position: absolute;
  bottom: -46px;
  left: 120px;
}

.testimonial-two__shape-1>img {
  width: auto !important;
}

.testimonial-two__shape-2 {
  position: absolute;
  left: 90px;
  top: 77px;
  z-index: -1;
}

.testimonial-two__shape-2>img {
  width: auto !important;
}

.testimonial-two__client-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 68px;
  margin-left: 70px;
}

.testimonial-two__client-img {
  position: relative;
  display: block;
  width: 84px;
}

.testimonial-two__client-img>img {
  width: 100% !important;
  border-radius: 50%;
}

.testimonial-two__client-content {
  margin-left: 18px;
}

.testimonial-two__client-name {
  font-size: 18px;
  color: var(--endreox-white);
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.testimonial-two__client-title {
  font-size: 14px;
  color: var(--endreox-white);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-left: 10px;
  margin-top: 6px;
}

.testimonial-two__carousel.owl-carousel .owl-dots {
  position: absolute;
  bottom: 59px;
  right: 30px;
  text-align: center;
  margin: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-two__carousel.owl-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--endreox-white);
  margin: 0px 5px;
  padding: 0px;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.testimonial-two__carousel.owl-carousel .owl-dot.active {
  width: 13px;
  height: 13px;
  background-color: #CE9B4E;
  border: 2px solid #CE9B4E;
}

.testimonial-two__carousel.owl-carousel .owl-dot:focus {
  outline: none;
}

.testimonial-two__carousel.owl-carousel .owl-dots .owl-dot span {
  display: none;
}

/*--------------------------------------------------------------
# Blog Two
--------------------------------------------------------------*/
.blog-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

/*--------------------------------------------------------------
# Services Four
--------------------------------------------------------------*/
.services-four {
  position: relative;
  display: block;
  background-color: #f8f7fa;
  counter-reset: count;
  padding: 100px 0 112px;
  z-index: 2;
}

.services-four__left {
  position: relative;
  display: block;
}

.services-four__left .section-title__title {
  color: #162b62;
}

.services-four__left .section-title {
  margin-bottom: 29px;
}

.services-four__btn {
  position: relative;
  display: block;
}

.services-four__btn a {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #d52b0a;
  display: inline-block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-four__btn a:hover {
  color: var(--endreox-black);
}

.services-four__btn a span {
  position: relative;
  display: inline-block;
  color: var(--endreox-black);
  margin-left: 48px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-four__btn a:hover span {
  color: var(--endreox-base);
}

.services-four__btn a::before {
  position: absolute;
  top: -3px;
  right: 11px;
  width: 34px;
  height: 34px;
  background-color: #1578ee;
  border-radius: 50%;
  content: "";
}

.services-four__right {
  position: relative;
  display: block;
  margin-top: -135px;
}

.services-four__single {
  position: relative;
  display: block;
  background-color: var(--endreox-white);
  padding: 60px 31px 46px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin-bottom: 30px;
}

.services-four__shape-1 {
  position: absolute;
  top: 105px;
  right: 47px;
}

.services-four__shape-1 img {
  width: auto;
}

.services-four__inner {
  position: relative;
  display: block;
}

.services-four__icon {
  position: relative;
  display: inline-block;
}

.services-four__icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--endreox-primary);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-four__single:hover .services-four__icon span {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.services-four__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  margin-top: 25px;
  margin-bottom: 17px;
}

.services-four__title a {
  color: #162b62;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-four__title a:hover {
  color: var(--endreox-base);
}

.services-four__text {
  font-weight: 500;
  color: #363636;
  font-size: 18px;
  line-height: 36px;
}

.services-four__count {
  position: absolute;
  top: 44px;
  left: 172px;
}

.services-four__count:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 70px;
  font-weight: 900;
  color: transparent;
  -webkit-text-stroke: 1px #ececec;
  font-family: var(--endreox-font-two);
  counter-increment: count;
  content: "0" counter(count);
}

.additional-services__two {
  position: relative;
  display: block;
  padding-top: 65px;
}

.additional-services__two-left {
  position: relative;
  display: block;
}

.additional-services__two-title {
  font-size: 30px;
  font-weight: 900;
  line-height: 36px;
  color: #58ABCF;
}

.additional-services__two-right {
  position: relative;
  display: block;
}

.additional-services__two-single {
  position: relative;
  display: block;
}

.additional-services__two-single li {
  position: relative;
  display: block;
  padding-left: 16px;
}

.additional-services__two-single li::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 16px;
  width: 7px;
  height: 1px;
  background-color: #162b62;
}

.additional-services__two-text {
  font-weight: 600;
  color: #162b62;
}

/*--------------------------------------------------------------
# Welcome One
--------------------------------------------------------------*/
.welcome-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.welcome-one__left {
  position: relative;
  display: block;
  margin-right: 44px;
}

.welcome-one__img-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.welcome-one__img {
  position: relative;
  display: block;
}

.welcome-one__img img {
  width: 100%;
}

.welcome-one__shape-1 {
  position: absolute;
  bottom: -67px;
  right: -61px;
  width: 187px;
  height: 187px;
  background-color: #ecdf0b;
  border-radius: 50%;
  z-index: 1;
}

.welcome-one__shape-2 {
  position: absolute;
  bottom: -101px;
  right: -58px;
  width: 221px;
  height: 221px;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
}

.welcome-one__right {
  position: relative;
  display: block;
  margin-right: 30px;
}

.welcome-one__right .section-title {
  margin-bottom: 23px;
}

.welcome-one__text-1 {
  font-size: 19px;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  color: #3c3c3c;
  line-height: 29px;
}

.welcome-one__text-2 {
  color: #494949;
  margin-top: 20px;
  margin-bottom: 25px;
}

.welcome-one__author-info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 8px;
}

.welcome-one__author-info {
  position: relative;
  display: block;
}

.welcome-one__author-info-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.welcome-one__author-img {
  position: relative;
  display: block;
}

.welcome-one__author-img img {
  width: auto;
  border-radius: 50%;
}

.welcome-one__author-content {
  position: relative;
  display: block;
  margin-left: 30px;
}

.welcome-one__author-name {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #0b1953;
}

.welcome-one__author-sub-title {
  font-size: 16px;
  line-height: 18px;
  color: #494949;
}

.welcome-one__author-signeture {
  position: relative;
  display: block;
  margin-left: 34px;
  margin-top: 21px;
}

.welcome-one__author-signeture img {
  width: auto;
}

.welcome-one__img-box-2 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 73px;
  margin-top: 15px;
}

.welcome-one__author-img-2 {
  position: relative;
  display: block;
}

.welcome-one__author-img-2 img {
  width: 100%;
}

.welcome-one__author-img-3 {
  position: relative;
  display: block;
  margin-left: 36px;
  margin-top: -13px;
}

.welcome-one__author-img-3 img {
  width: 100%;
}

/*--------------------------------------------------------------
# Project Three
--------------------------------------------------------------*/
.project-three {
  position: relative;
  display: block;
  background-color: #0a3974;
  padding: 120px 0 90px;
}

.project-three .section-title {
  margin-bottom: 31px;
}

.project-three .section-title__title {
  color: var(--endreox-white);
}

.project-three .section-title__tagline {
  margin-left: 0;
}

.project-three .section-title__tagline::after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.project-three .project-one__filter.style1 li .filter-text {
  color: var(--endreox-white);
}

.project-three .project-one__filter.style1 li:hover .filter-text,
.project-three.project-one__filter.style1 li.active .filter-text {
  color: var(--endreox-white);
}

.project-three .project-one__filter.style1 li .filter-text:before {
  background-color: var(--endreox-white);
}

/*--------------------------------------------------------------
# Why Choose Two
--------------------------------------------------------------*/
.why-choose-two {
  position: relative;
  display: block;
  background-color: #f8f7fa;
  padding: 120px 0 100px;
  border-bottom: 1px solid #c6c7cc;
}

.why-choose-two__left {
  position: relative;
  display: block;
  margin-left: -42px;
}

.why-choose-two__img-box {
  position: relative;
  display: block;
}

.why-choose-two__img {
  position: relative;
  display: block;
  z-index: 1;
}

.why-choose-two__img:before {
  position: absolute;
  left: -10000px;
  right: 240px;
  bottom: 0;
  top: -120px;
  background-color: #0a3974;
  content: "";
  z-index: -1;
}

.why-choose-two__img:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -335px;
  border-top: 535px solid transparent;
  border-left: 535px solid #009cde;
  mix-blend-mode: multiply;
  opacity: .30;
  z-index: -1;
}

.why-choose-two__img img {
  width: 100%;
}

.why-choose-two__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-right: -127px;
}

.why-choose-two__right .section-title {
  margin-bottom: 29px;
}

.why-choose-two__text {
  color: #494949;
}

.why-choose-two__points-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 54px;
}

.why-choose-two__points {
  position: relative;
  display: block;
}

.why-choose-two__points+.why-choose-two__points {
  margin-left: 120px;
}

.why-choose-two__points li {
  position: relative;
  display: block;
}

.why-choose-two__points li+li {
  margin-top: 37px;
}

.why-choose-two__points-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.why-choose-two__points-content .icon {
  position: relative;
  display: inline-block;
  top: 3px;
  z-index: 1;
}

.why-choose-two__points-content .icon::before {
  position: absolute;
  top: -6px;
  right: -5px;
  width: 32px;
  height: 32px;
  background-color: #ececec;
  border-radius: 50%;
  content: "";
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: -1;
}

.why-choose-two__points li:hover .why-choose-two__points-content .icon::before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.why-choose-two__points-content .icon span {
  position: relative;
  display: inline-block;
  font-size: 50px;
  color: #58ABCF;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.why-choose-two__points li:hover .why-choose-two__points-content .icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.why-choose-two__points-content .title {
  position: relative;
  display: block;
  margin-left: 27px;
}

.why-choose-two__points-content .title h3 {
  font-size: 21px;
  font-weight: 900;
  line-height: 26px;
  color: #58ABCF;
}

.why-choose-two__points li .text {
  font-size: 17px;
  line-height: 26px;
  color: #525252;
  margin-top: 17px;
}

/*--------------------------------------------------------------
# Counter Three
--------------------------------------------------------------*/
.counter-three {
  position: relative;
  display: block;
  background-color: #f8f7fa;
  padding: 89px 0 104px;
  z-index: 1;
}

.counter-three__count-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.counter-three__count-box li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 13px;
}

.counter-three__icon {
  position: relative;
  display: inline-block;
  top: 11px;
  margin-right: 27px;
  z-index: 1;
}

.counter-three__icon::before {
  position: absolute;
  top: 25px;
  left: -23px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #dfeaf9;
  content: "";
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: -1;
  z-index: -1;
}

.counter-three__count-box li:hover .counter-three__icon::before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.counter-three__icon span {
  position: relative;
  display: inline-block;
  font-size: 56px;
  color: var(--endreox-primary);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.counter-three__count-box li:hover .counter-three__icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.counter-three__content {
  position: relative;
  display: block;
}

.counter-three__content h3 {
  font-size: 50px;
  font-weight: 800;
  line-height: 50px !important;
  color: #58ABCF;
  font-family: var(--endreox-font) !important;
}

.counter-three__letter {
  font-size: 50px;
  font-weight: 800;
  color: #58ABCF;
  font-family: var(--endreox-font) !important;
  position: relative;
  top: 14px;
  left: 11px;
}

.counter-three__text {
  font-size: 18px;
  font-weight: 600;
  color: #58ABCF;
  line-height: 19px;
}

.counter-three__count-box .odometer-formatting-mark {
  display: none;
}

/*--------------------------------------------------------------
# Contact One
--------------------------------------------------------------*/
.contact-one {
  position: relative;
  display: block;
  background-color: #eff2f5;
  padding: 120px 0 112px;
}

.contact-one__shape-1 {
  position: absolute;
  top: -46px;
  left: -63px;
  opacity: .40;
}

.contact-one__shape-1 {
  width: auto;
}

.contact-one__left {
  position: relative;
  display: block;
}

.contact-one__left .section-title {
  margin-bottom: 12px;
}

.contact-one__phone {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contact-one__phone-img {
  position: relative;
  display: block;
  width: 70px;
}

.contact-one__phone-img img {
  width: 100%;
  border-radius: 50%;
}

.contact-one__phone-content {
  margin-left: 30px;
}

.contact-one__phone-content p {
  color: #444444;
  font-weight: 600;
}

.contact-one__phone-content h4 {
  font-size: 22px;
  line-height: 22px;
  font-family: var(--endreox-font);
  margin-top: 2px;
  font-weight: 600;
}

.contact-one__phone-content h4 a {
  color: #58ABCF;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-one__phone-content h4 a:hover {
  color: var(--endreox-base);
}

.contact-one__time {
  color: #444444;
}

.contact-one__address {
  position: relative;
  display: block;
  margin-top: 20px;
}

.contact-one__address h4 {
  font-size: 18px;
  color: #444444;
  font-weight: 600;
  font-family: var(--endreox-font);
}

.contact-one__address p {
  color: #444444;
}

.contact-one__right {
  position: relative;
  display: block;
}

.contact-one__form {
  position: relative;
  display: block;
}

.contact-one__input-box {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.contact-one__input-box input[type="text"],
.contact-one__input-box input[type="email"] {
  height: 60px;
  width: 100%;
  background-color: var(--endreox-white);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 18px;
  color: #444444;
  display: block;
  border: 1px solid #dbdcdd;
  border-radius: 8px;
}

.contact-one__input-box textarea {
  font-size: 18px;
  color: #444444;
  height: 200px;
  width: 100%;
  background-color: var(--endreox-white);
  padding: 18px 30px 30px;
  border: 1px solid #dbdcdd;
  border-radius: 8px;
  outline: none;
  margin-bottom: 0px;
}

.contact-one__btn {
  background-image: -ms-linear-gradient(0deg, #d90429 0%, #d91817 57%, #d92c04 100%);
  border: none;
  width: 100%;
  border-radius: 4px;
}

.contact-one__btn span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 16px;
  margin-left: 15px;
  top: -1px;
}

.contact-one__input-box.text-message-box {
  height: 200px;
}

/*--------------------------------------------------------------
# Blog Details
--------------------------------------------------------------*/
.blog-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.blog-details__left {
  position: relative;
  display: block;
}

.blog-detals__top {
  position: relative;
  display: block;
}

.blog-details__img {
  position: relative;
  display: block;
}

.blog-details__img img {
  width: 100%;
}

.blog-details__date {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: var(--endreox-white);
  text-align: center;
  height: 82px;
  width: 82px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.blog-details__date p {
  font-size: 32px;
  color: var(--endreox-black);
  font-weight: 600;
  line-height: 32px;
  font-family: var(--endreox-font-two);
}

.blog-details__date span {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 18px;
  color: var(--endreox-black);
  font-weight: 500;
}

.blog-details__meta-box {
  position: relative;
  display: block;
  margin-top: 18px;
}

.blog-details__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 19px;
}

.blog-details__meta li {
  position: relative;
  display: block;
}

.blog-details__meta li:before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 7px;
  left: -22px;
  background-color: #50658f;
  width: 1px;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

.blog-details__meta li:first-child:before {
  display: none;
}

.blog-details__meta li+li {
  margin-left: 45px;
}

.blog-details__meta li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: #50658f;
  font-weight: 500;
  transition: all 500ms ease;
}


.blog-details__meta li a:hover {
  color: var(--endreox-base);
}

.blog-details__meta li a i {
  font-size: 23px;
  color: var(--endreox-black);
  margin-right: 15px;
}

.blog-detals__content-box {
  position: relative;
  display: block;
  margin-top: 31px;
}

.blog-details__title-1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 11px;
}

.blog-details__text-1 {
  color: #444444;
}

.blog-detals__two-colum-box {
  position: relative;
  display: block;
  margin-top: 34px;
}

.blog-detals__two-colum-box-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 16px;
}

.blog-detals__two-colum-text {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-detals__two-colum-text li {
  color: #444444;
}

.blog-detals__two-colum-text li+li {
  margin-left: 55px;
}

.blog-details__text-2 {
  color: #444444;
  padding-top: 42px;
}

.blog-details__text-2 a {
  color: #bf1909;
  display: inline-block;
  text-decoration: underline;
}

.comment-one-box {
  position: relative;
  display: block;
  margin-top: 29px;
}

.comment-one__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 26px;
}

.comment-one {
  position: relative;
  display: block;
  border: 1px solid #eeeeee;
}

.comment-one__single {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 25px 23px;
}

.comment-one__image {
  position: relative;
  display: block;
  max-width: 70px;
  width: 100%;
}

.comment-one__image img {
  width: 100%;
}

.comment-one__content {
  margin-left: 30px;
  margin-top: -2px;
}

.comment-one__content h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  font-family: var(--endreox-font);
}

.comment-one__content p {
  color: #444444;
  line-height: 23px;
  padding-top: 8px;
  padding-bottom: 12px;
}

.comment-one__content h4 {
  font-size: 17px;
  color: #7c7c7c;
  font-weight: 600;
  font-family: var(--endreox-font);
  line-height: 17px;
}

.comment-one__content h4 a {
  color: var(--endreox-base);
  position: relative;
  display: inline-block;
  padding-left: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.comment-one__content h4 a:hover {
  color: var(--endreox-primary);
}

.comment-one__single--four {
  padding-left: 125px;
}

.comment-one__single.border-none {
  border-bottom: 0;
}

.comment-one__single-2 {
  background-color: #fcfcfc;
}

.comment-one__single-5 {
  background-color: #fcfcfc;
}

.comment-form {
  position: relative;
  display: block;
  margin-top: 52px;
}

.comment-one__form {
  position: relative;
  display: block;
  background-color: #f7f7f7;
  padding: 50px 35px 40px;
  border: 1px solid #eeeeee;
}

.comment-form__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 26px;
}

.comment-one__form .row {
  --bs-gutter-x: 20px;
}

.comment-form__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.comment-form__input-box input[type="text"],
.comment-form__input-box input[type="email"] {
  height: 45px;
  width: 100%;
  border: 1px solid #eeeeee;
  background-color: var(--endreox-white);
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  font-size: 17px;
  color: var(--endreox-gray);
  display: block;
  font-weight: 400;
}

.comment-form__input-box textarea {
  font-size: 17px;
  color: var(--endreox-gray);
  height: 174px;
  width: 100%;
  border: 1px solid #eeeeee;
  background-color: var(--endreox-white);
  padding: 15px 20px 15px;
  outline: none;
  margin-bottom: 0px;
  font-weight: 400;
}

.comment-form__btn {
  background-image: -ms-linear-gradient(0deg, #d71426 0%, #d72213 57%, #d73000 100%);
  border: none;
  border-radius: 5px;
  padding-right: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comment-form__btn span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  width: 20px;
  font-size: 10px;
  border-radius: 50%;
  border: 2px solid var(--endreox-white);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 25px;
}

.comment-form__input-box.text-message-box {
  height: 175px;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar {
  position: relative;
  display: block;
  margin-left: 30px;
}

.sidebar__title {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 26px;
  font-weight: 700;
}

.sidebar__title:before {
  content: "";
  position: absolute;
  bottom: 4px;
  right: -51px;
  width: 35px;
  height: 2px;
  background-color: var(--endreox-black);
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}

.sidebar__search-form input[type="search"] {
  display: block;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  color: #909090;
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
  height: 50px;
  width: 100%;
  padding-right: 80px;
  border-radius: 5px;
}

.sidebar__search-form ::-webkit-input-placeholder {
  color: #909090;
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: #909090;
  opacity: 1;
}

.sidebar__search-form ::-ms-input-placeholder {
  color: #909090;
  opacity: 1;
}

.sidebar__search-form ::placeholder {
  color: #909090;
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: #909090;
}

.sidebar__search-form ::-ms-input-placeholder {
  color: #909090;
}

.sidebar__search-form button[type="submit"] {
  background-color: transparent;
  color: #4c4c4c;
  font-size: 19px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 55px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.sidebar__category {
  position: relative;
  display: block;
  margin-top: 45px;
  margin-bottom: 37px;
  z-index: 1;
}

.sidebar__category .sidebar__title {
  margin-bottom: 11px;
}

.sidebar__category-list {
  margin: 0;
}

.sidebar__category-list li a {
  color: var(--endreox-gray);
  font-size: 18px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 5px 0 7px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

.sidebar__category-list li:hover a {
  color: var(--endreox-black);
}

.sidebar__category-list li:last-child a {
  border-bottom: 0;
  padding-bottom: 0;
}

.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #d3d3d3;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
}

.sidebar__category-list li:hover a span {
  color: var(--endreox-black);
}

.sidebar__post {
  position: relative;
  display: block;
  z-index: 1;
}

.sidebar__post .sidebar__title {
  margin-left: 0px;
}

.sidebar__post-list {
  margin: 0;
}

.sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 19px;
  margin-bottom: 19px;
}

.sidebar__post-list li:last-child {
  margin-bottom: 0;
}

.sidebar__post-image {
  margin-right: 25px;
}

.sidebar__post-image>img {
  width: 70px;
}

.sidebar__post-content {
  position: relative;
  display: block;
  top: 1px;
}

.sidebar__post-content h3 {
  font-size: 17px;
  margin: 0;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 3px;
  font-family: var(--endreox-font);
}

.sidebar__post-content h3 a {
  color: #444444;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post-content h3 a:hover {
  color: var(--endreox-base);
}

.sidebar__post-content p {
  font-size: 16px;
  color: var(--endreox-black);
  font-weight: 500;
}

.sidebar__tags {
  position: relative;
  display: block;
  margin-top: 45px;
  z-index: 1;
}

.sidebar__tags-list {
  margin-top: -10px;
  margin-left: -10px;
}

.sidebar__tags-list a {
  font-size: 16px;
  color: #404040;
  border: 1px solid #e4e4e4;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block;
  padding: 3px 18px 3px;
  margin-left: 10px;
  font-weight: 400;
}

.sidebar__tags-list a+a {
  margin-left: 10px;
  margin-top: 10px;
}

.sidebar__tags-list a:hover {
  color: var(--endreox-white);
  background: var(--endreox-primary);
  border: 1px solid var(--endreox-primary);
}

/*--------------------------------------------------------------
# Blog Page
--------------------------------------------------------------*/
.blog-page {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.blog-page__left {
  position: relative;
  display: block;
}

.blog-page__single {
  position: relative;
  display: block;
}

.blog-page__single+.blog-page__single {
  margin-top: 80px;
}

.blog-page__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.blog-page__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(0, 122, 239, 0.9);
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  z-index: 1;
}

.blog-page__single:hover .blog-page__img:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.blog-page__img img {
  width: 570px;
  height: 380px;
}

.blog-page__date {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: var(--endreox-white);
  text-align: center;
  height: 80px;
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 2;
}

.blog-page__date p {
  font-size: 32px;
  color: var(--endreox-black);
  font-weight: 600;
  line-height: 32px;
  font-family: var(--endreox-font-two);
}

.blog-page__date span {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 18px;
  color: var(--endreox-black);
  font-weight: 500;
}

.blog-page__content {
  position: relative;
  display: block;
  margin-top: 17px;
}

.blog-page__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 19px;
}

.blog-page__meta li {
  position: relative;
  display: block;
}

.blog-page__meta li:before {
  content: "";
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: -22px;
  background-color: #50658f;
  width: 1px;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

.blog-page__meta li:first-child:before {
  display: none;
}

.blog-page__meta li+li {
  margin-left: 45px;
}

.blog-page__meta li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  color: #50658f;
  font-weight: 500;
  transition: all 500ms ease;
}

.blog-page__meta li a:hover {
  color: var(--endreox-base);
}

.blog-page__meta li a i {
  font-size: 23px;
  color: var(--endreox-black);
  margin-right: 15px;
}

.blog-page__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  margin-top: 31px;
  margin-bottom: 18px;
}

.blog-page__title a {
  color: var(--endreox-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-page__title a:hover {
  color: var(--endreox-base);
}

.blog-page__btn-box {
  position: relative;
  display: block;
  margin-top: 37px;
}

.blog-page__btn {
  font-size: 14px;
  background-image: -ms-linear-gradient(0deg, #d71426 0%, #d72213 57%, #d73000 100%);
  padding: 6px 25px 6px;
  border-radius: 3px;
}

.blog-page__pagination {
  position: relative;
  display: block;
  padding: 50px 0 0px;
}

.blog-page__pagination .pg-pagination li {
  display: inline-block;
  margin-right: 7px;
}

.blog-page__pagination .pg-pagination li:last-child {
  margin-right: 0;
}

.blog-page__pagination .pg-pagination li a {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  display: inline-block;
  color: var(--endreox-black);
  background-color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: 1px solid #d4d4d4;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-page__pagination .pg-pagination li.active a,
.blog-page__pagination .pg-pagination li a:hover {
  background-color: var(--endreox-base);
  color: var(--endreox-white);
  border: 1px solid var(--endreox-base);
}

.enquiry_section {
  background-color: #58ABCF;
  padding: 50px;
  margin: 50px 0;
}

.enquiry_section h1 {
  color: white;
  text-align: center;
  padding-bottom: 20px;
}

.enquiry_section .contact-page__input-box input[type="text"],
.enquiry_section .contact-page__input-box input[type="email"] {
  color: white;
}

.enquiry_section .contact-page__input-box textarea {
  color: white;
}

.enquiry_section .contact-page__btn-box {
  text-align: center;
}

.enquiry_section .contact-page__btn-box button {
  background-color: #CE9B4E;
}

/*--------------------------------------------------------------
# Blog Grid
--------------------------------------------------------------*/
.blog-grid {
  position: relative;
  display: block;
  padding: 120px 0 40px;
}

.blog-grid .blog-page__single {
  margin-bottom: 80px;
}

.blog-grid .blog-page__pagination {
  padding: 0px 0 0px;
  text-align: center;
}

/*--------------------------------------------------------------
# Project Details
--------------------------------------------------------------*/
.project-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.project-details__top {
  position: relative;
  display: block;
}

.project-details__top-img {
  position: relative;
  display: block;
}

.project-details__top-img img {
  width: 100%;
}

.project-details__info-box {
  position: relative;
  display: block;
  background-color: #d90429;
  padding: 48px 43px 75px;
}

.project-details__info-box p {
  color: var(--endreox-white);
}

.project-details__info-box p+p {
  margin-top: 18px;
}

.project-details__info-box p span {
  font-weight: 700;
}

.project-details__info-box p a {
  color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__info-box p a:hover {
  color: var(--endreox-primary);
}

.project-description {
  position: relative;
  display: block;
}

.project-description__title {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
  margin-top: 91px;
  margin-bottom: 25px;
}

.project-description__text-1 {
  position: relative;
  display: block;
}

.project-description__text-2 {
  position: relative;
  display: block;
  margin-top: 26px;
}

/*--------------------------------------------------------------
# What We did
--------------------------------------------------------------*/
.what-we-did {
  position: relative;
  display: block;
  background-color: #f4f4f4;
  padding: 86px 0 97px;
}

.what-we-did__left {
  position: relative;
  display: block;
  margin-right: 24px;
}

.what-we-did__title {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
}

.what-we-did__text {
  position: relative;
  display: block;
  margin-bottom: 30px;
  margin-top: 22px;
}

.what-we-did__points {
  position: relative;
  display: block;
  margin-left: 35px;
  margin-top: 30px;
}

.what-we-did__points li {
  position: relative;
  display: block;
}

.what-we-did__points li::before {
  content: "";
  position: absolute;
  left: -35px;
  top: 11px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--endreox-primary);
}

.what-we-did__points li+li {
  margin-top: 30px;
}

.what-we-did__points-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #182158;
  font-family: var(--endreox-font);
  margin-bottom: 7px;
}

.what-we-did__right {
  position: relative;
  display: block;
}

.what-we-did__img {
  position: relative;
  display: block;
  margin-top: 39px;
}

.what-we-did__img img {
  width: 100%;
}

/*--------------------------------------------------------------
# Final Result
--------------------------------------------------------------*/
.final-result {
  position: relative;
  display: block;
  padding: 109px 0 112px;
}

.final-result__title {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
}

.final-result__text-1 {
  margin-top: 22px;
  margin-bottom: 26px;
}

.final-result__text-2 {
  position: relative;
  display: block;
}

.final-result__points-box {
  position: relative;
  display: block;
  margin-left: 32px;
  margin-top: 35px;
}

.final-result__points-box li {
  position: relative;
  display: block;
}

.final-result__points-box li::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -32px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--endreox-primary);
}

.final-result__points-box li+li {
  margin-top: 4px;
}

.final-result__points-box li p {
  position: relative;
  display: block;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
  padding: 205px 0 131px;
  background-color: #081037;
  overflow: hidden;
  z-index: 1;
}

.page-header-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 55.5%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  mix-blend-mode: hard-light;
  opacity: 0.08;
  z-index: -1;
}

.page-header__inner {
  position: relative;
  display: block;
  z-index: 15;
}

.page-header__shape-1 {
  position: absolute;
  right: 190px;
  bottom: -54px;
}

.page-header__shape-1 img {
  width: auto;
}

.page-header__inner h1,
.page-header__inner h2 {
  font-size: 60px;
  color: var(--endreox-white);
  font-weight: 700;
  line-height: 65px;
  margin-bottom: 7px;
}

.thm-breadcrumb {
  position: relative;
  display: block;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #cccbd6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li+li {
  margin-left: 5px;
}

.thm-breadcrumb li a {
  position: relative;
  display: inline-block;
  color: #cccbd6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
  color: var(--endreox-base);
}

.ul {
  list-style-type: disclosure-closed;
  padding-left: 20 px;
}

/*--------------------------------------------------------------
# About Three
--------------------------------------------------------------*/
.about-three {
  position: relative;
  display: block;
  padding: 120px 0 10px;
}

.about-three__left {
  position: relative;
  display: block;
}

.about-three__left .section-title {
  margin-bottom: 19px;
}

.about-three__text-2 {
  font-size: 20px;
  font-weight: 500;
  color: #0e2a4e;
  padding-top: 28px;
  padding-bottom: 47px;
}

.about-three__text-2 a {
  position: relative;
  display: inline-block;
  color: var(--endreox-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-three__text-2 a:hover {
  color: var(--endreox-primary);
}

.about-three__text-2 a:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 1px;
  background-color: var(--endreox-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-three__text-2 a:hover:before {
  background-color: var(--endreox-primary);
}

.about-three__points {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-three__points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-three__points li+li {
  margin-left: 90px;
}

.about-three__points li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-three__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: var(--endreox-primary);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.about-three__points li:hover .icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.about-three__points li .text {
  margin-left: 30px;
}

.about-three__points li .text p {
  font-size: 20px;
  font-weight: 600;
  color: var(--endreox-black);
  line-height: 26px;
}

.about-three__right {
  position: relative;
  display: block;
  margin-left: 60px;
}

.about-three__img {
  position: relative;
  display: block;
}

.about-three__img img {
  width: 100%;
  border-radius: 10px;
}

.about-three__experience {
  position: absolute;
  bottom: -20px;
  right: -25px;
  background-color: #58ABCF;
  text-align: center;
  border-radius: 10px;
  padding: 22px 30px 31px;
}

.about-three__experience h3 {
  font-size: 60px;
  color: var(--endreox-white);
  font-weight: 700;
  font-family: var(--endreox-font);
  line-height: 60px;
  margin-bottom: 9px;
}

.about-three__experience p {
  font-size: 18px;
  font-weight: 600;
  color: var(--endreox-white);
  line-height: 21px;
}

/*--------------------------------------------------------------
# Benefits
--------------------------------------------------------------*/
.benefits {
  position: relative;
  display: block;
  background-color: #ecf1f5;
  overflow: hidden;
  padding: 120px 0 90px;
  z-index: 1;
}

.benefits .section-title {
  margin-bottom: 52px;
}

.benefits .section-title__tagline {
  margin-left: 0;
}

.benefits .section-title__tagline:after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.benefits__text-1 {
  padding-top: 4px;
}

.benefits__single {
  position: relative;
  display: block;
  background-color: var(--endreox-white);
  border-radius: 5px;
  padding: 50px 30px 46px;
  margin-bottom: 30px;
}

.benefits__single-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.benefits__icon {
  position: relative;
  display: inline-block;
}

.benefits__icon span {
  position: relative;
  display: inline-block;
  font-size: 65px;
  color: var(--endreox-primary);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.benefits__single:hover .benefits__icon span {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.benefits__content {
  position: relative;
  margin-left: 22px;
  top: -4px;
}

.benefits__title {
  font-size: 24px;
  color: #111e37;
  line-height: 27px;
  font-weight: 700;
  margin-bottom: 16px;
}

.benefits__text-2 {
  line-height: 28px;
}

/*--------------------------------------------------------------
# Mission One
--------------------------------------------------------------*/
.mission-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.mission-one__inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mission-one__img {
  position: relative;
  display: block;
  max-width: 370px;
  width: 100%;
}

.mission-one__img>img {
  width: 100%;
  border-radius: 10px;
}

.mission-one__content-box {
  position: relative;
  display: block;
  margin-left: 50px;
}

.mission-one__content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mission-one__text {
  font-size: 20px;
  font-weight: 700;
  color: #58ABCF;
}

.mission-one__sign {
  margin-left: 100px;
}

.mission-one__sign>img {
  width: auto;
}

/*--------------------------------------------------------------
# About Company
--------------------------------------------------------------*/
.about-company {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.about-company__top {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.about-company__left {
  position: relative;
  display: block;
  margin-right: 30px;
}

.about-company__img {
  position: relative;
  display: block;
}

.about-company__img img {
  width: 100%;
  border-radius: 10px;
}

.about-company__right {
  position: relative;
  display: block;
}

.about-company__right .section-title {
  margin-bottom: 20px;
}

.about-company__text-1 {
  font-size: 20px;
  font-weight: 500;
  color: var(--endreox-black);
}

.about-company__text-2 {
  padding-top: 5px;
  padding-bottom: 27px;
  color: #444444;
}

.about-company__text-3 {
  color: #444444;
}

.about-company__bottom {
  position: relative;
  display: block;
}

.about-company__bottom-left {
  position: relative;
  display: block;
  margin-right: 30px;
}

.about-company__bottom-img {
  position: relative;
  display: block;
}

.about-company__bottom-img img {
  width: 100%;
  border-radius: 10px;
}

.about-company__bottom-right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 55px;
  margin-left: 15px;
}

.about-company__text-4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--endreox-black);
  position: relative;
  display: block;
  max-width: 465px;
}

.about-company__sign {
  position: relative;
  display: block;
  margin-left: 45px;
}

.about-company__sign img {
  width: auto;
}

/*--------------------------------------------------------------
# Company History
--------------------------------------------------------------*/
.company-history {
  position: relative;
  display: block;
}

.company-history__inner {
  position: relative;
  display: block;
}

.company-history__border {
  position: absolute;
  top: 210px;
  left: 57px;
  height: 636px;
  width: 1px;
  background-color: #d2d2d2;
}

.company-history__list {
  position: relative;
  display: block;
  border-top: 1px solid #e0e0e0;
  padding: 110px 0 90px;
}

.company-history__list li {
  position: relative;
  display: block;
  padding-left: 170px;
}

.company-history__list li+li {
  margin-top: 60px;
}

.company-history__year {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 110px;
  width: 110px;
  background-color: #CE9B4E;
  border-radius: 50%;
  top: 9px;
  left: 0;
}

.company-history__year p {
  font-size: 32px;
  color: var(--endreox-black);
  font-weight: 600;
  line-height: 32px;
}

.company-history__content {
  position: relative;
  display: block;
}

.company-history__title {
  font-size: 34px;
  font-weight: 900;
  line-height: 44px;
  margin-bottom: 9px;
}

.company-history__text {
  color: #444444;
}

.company-history__img-boxes {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 62px;
}

.company-history__img-one {
  position: relative;
  display: block;
  max-width: 400px;
  margin-bottom: 30px;
}

.company-history__img-one img {
  width: 100%;
  border-radius: 10px;
}

.company-history__img-two {
  position: relative;
  display: block;
  max-width: 400px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.company-history__img-two img {
  width: 100%;
  border-radius: 10px;
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-page {
  position: relative;
  display: block;
  padding: 120px 0 50px;
}

.contact-page__left {
  position: relative;
  display: block;
}

.contact-page__form {
  position: relative;
  display: block;
}

.contact-page__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-page__input-box input[type="text"],
.contact-page__input-box input[type="email"] {
  height: 55px;
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  font-size: 18px;
  color: #444444;
  display: block;
  border: 1px solid #e1e1e1;
  border-radius: 0;
}

.contact-page__input-box textarea {
  font-size: 18px;
  color: #444444;
  height: 230px;
  width: 100%;
  background-color: transparent;
  padding: 18px 30px 30px;
  border: 1px solid #e1e1e1;
  border-radius: 0px;
  outline: none;
  margin-bottom: 0px;
}

.contact-page__btn-box {
  position: relative;
  display: block;
  padding-top: 20px;
}

.contact-page__btn {
  border: none;
  padding-right: 25px;
  border-radius: 4px;
}

.contact-page__btn span {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  color: var(--endreox-white);
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  top: -1px;
  margin-left: 13px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.contact-page__input-box.text-message-box {
  height: 230px;
}

.contact-page__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: -4px;
}

.contact-page__content {
  position: relative;
  display: block;
}

.contact-page__title {
  font-size: 35px;
  line-height: 35px;
  font-weight: 900;
}

.contact-page__text {
  padding-top: 29px;
  padding-bottom: 44px;
}

.contact-page__content-info {
  position: relative;
  display: block;
}

.contact-page__content-info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-page__content-info li+li {
  margin-top: 14px;
}

.contact-page__content-info li .icon {
  position: relative;
  display: block;
}

.contact-page__content-info li .icon span {
  position: relative;
  display: inline-block;
  font-size: 35px;
  color: var(--endreox-primary);
}

.contact-page__content-info li .content {
  margin-left: 30px;
}

.contact-page__content-info li .content h4 {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--endreox-font);
  line-height: 20px;
}

.contact-page__content-info li .content p a {
  color: var(--endreox-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-page__content-info li:hover .content p a {
  color: var(--endreox-base);
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.contact-page-google-map {
  position: relative;
  display: block;
}

.contact-page-google-map__inner {
  position: relative;
  display: block;
  border-top: 1px solid #d6d6d6;
  padding-bottom: 120px;
}

.google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 390px;
  width: 100%;
}

/*--------------------------------------------------------------
# FAQ Page
--------------------------------------------------------------*/
.faq-page {
  position: relative;
  display: block;
  padding: 120px 0 104px;
}

.faq-page .section-title {
  margin-bottom: 52px;
}

.faq-page .section-title__text {
  padding-top: 10px;
  color: #444444;
}

.faq-page__search {
  position: relative;
  display: block;
  padding-bottom: 52px;
}

.faq-page__search-form {
  position: relative;
}

.faq-page__search-form input[type="search"] {
  display: block;
  border: 1px solid #e0e0e0;
  outline: none;
  background-color: transparent;
  color: #444444;
  font-size: 18px;
  font-weight: 400;
  padding-left: 25px;
  height: 60px;
  width: 100%;
  padding-right: 55px;
  border-radius: 5px;
}

.faq-page__search-form button[type="submit"] {
  background-color: transparent;
  color: #e0e0e0;
  font-size: 19px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.faq-page__left {
  position: relative;
  display: block;
}

.faq-page__right {
  position: relative;
  display: block;
}

.faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  border-radius: 0;
  border: 0;
}

.faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 11px 20px 11px;
  padding-left: 55px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 22px;
  color: #111e37;
  font-weight: 700;
  line-height: 28px;
  font-family: var(--endreox-font);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.faq-one-accrodion .accrodion-title h4::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px;
  color: var(--endreox-black);
  position: absolute;
  top: 50%;
  left: -45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-align: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f068";
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq-one-accrodion .accrodion-content {
  position: relative;
  padding: 0px 55px 13px;
  margin-top: 0;
}

.faq-one-accrodion .accrodion-content p {
  margin: 0;
  color: #444444;
  font-size: 17px;
}

/*--------------------------------------------------------------
# Ask Question
--------------------------------------------------------------*/
.ask-question {
  position: relative;
  display: block;
  background-color: #edf1f4;
  padding: 109px 0 120px;
  overflow: hidden;
  z-index: 1;
}

.ask-question__img {
  position: absolute;
  bottom: 0;
  right: 0;
  mix-blend-mode: darken;
  z-index: -1;
}

.ask-question__img img {
  width: auto;
}

.ask-question__inner {
  position: relative;
  display: block;
}

.ask-question__title {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
  padding-bottom: 46px;
}

.ask-question__form {
  position: relative;
  display: block;
  margin-right: 100px;
}

.ask-question__input-box {
  position: relative;
  display: block;
  margin-bottom: 23px;
}

.ask-question__input-box input[type="text"],
.ask-question__input-box input[type="email"] {
  height: 65px;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #e3e3e3;
  background-color: transparent;
  padding: 0;
  outline: none;
  font-size: 18px;
  color: #444444;
  display: block;
  font-weight: 400;
}

.ask-question__input-box textarea {
  font-size: 18px;
  color: #444444;
  height: 150px;
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: 0;
  outline: none;
  font-weight: 400;
}

.ask-question__btn-box {
  position: relative;
  display: block;
  padding-top: 7px;
}

.ask-question__btn {
  border: none;
  font-size: 14px;
  padding: 12px 30px 8px;
  border-radius: 4px;
}

.ask-question__input-box.text-message-box {
  height: 150px;
  padding-top: 17px;
  border-bottom: 2px solid #e3e3e3;
}

/*--------------------------------------------------------------
# Special Offer
--------------------------------------------------------------*/
.special-offer {
  position: relative;
  display: block;
  padding: 120px 0 0;
}

.special-offer .section-title {
  margin-bottom: 52px;
}

.special-offer__sec-title-text {
  padding-top: 16px;
}

.special-offer__bottom {
  position: relative;
  display: block;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 90px;
}

.special-offer__single-1 .coupons__offer-amount {
  background-color: #58ABCF;
}

.special-offer__single-2 .coupons__offer-amount {
  background-color: #d71426;
}

.special-offer__single-3 .coupons__offer-amount {
  background-color: #0591dd;
}

/*--------------------------------------------------------------
# Price Table
--------------------------------------------------------------*/
.price-table {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.price-table .section-title__tagline {
  margin-left: 0;
}

.price-table .section-title__tagline::after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.price-table__single {
  position: relative;
  display: block;
  border: 1px solid #cccccc;
  border-radius: 7px;
  padding: 34px 34px 32px;
  margin-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.price-table__single:hover {
  background-color: #f8f8f8;
}

.price-table__title-box {
  position: relative;
  display: block;
  text-align: center;
  border-bottom: 1px solid #cccccc;
  padding: 0 0 20px;
}

.price-table__tagline {
  font-size: 14px;
  color: var(--endreox-primary);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  line-height: 14px;
}

.price-table__title {
  font-size: 32px;
  color: #091c55;
  font-weight: 700;
  line-height: 42px;
  margin-top: 12px;
}

.price-table__title span {
  font-weight: 300;
}

.price-table__list {
  position: relative;
  display: block;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 34px;
}

.price-table__list li {
  position: relative;
  display: block;
  padding-left: 15px;
}

.price-table__list li:before {
  content: "";
  position: absolute;
  top: 16px;
  left: 0;
  height: 1px;
  width: 5px;
  background-color: #444444;
}

.price-table__list li+li {
  margin-top: 10px;
}

.price-table__price-box {
  position: relative;
  display: block;
  text-align: center;
}

.price-table__price {
  font-size: 32px;
  color: #091c55;
  font-weight: 700;
  line-height: 32px;
  font-family: var(--endreox-font);
}

.price-table__price span {
  font-size: 20px;
  font-weight: 500;
}

.price-table__btn {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 20px;
}

.price-table__btn a {
  font-size: 14px;
  font-weight: 700;
  color: var(--endreox-base);
  font-family: var(--endreox-font-two);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
  display: inline-block;
  line-height: 14px;
}

/*--------------------------------------------------------------
# Additional Services Three
--------------------------------------------------------------*/
.additional-services-three {
  position: relative;
  display: block;
  background-color: #ffffff;
  overflow: hidden;
  padding: 0px 0px 90px;
}

.additional-services-three .section-title {
  margin-bottom: 52px;
}

.additional-services-three .section-title__text {
  padding-top: 14px;
}

.additional-services-three__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.additional-services-three__img {
  position: relative;
  display: block;
  background-color: var(--endreox-black);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.additional-services-three__img img {
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.additional-services-three__single:hover .additional-services-three__img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  opacity: .50;
}

.additional-services-three__content {
  position: relative;
  display: block;
  text-align: center;
  background-color: #f1f1f1;
  padding: 25px 0 20px;
}

.additional-services-three__content h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 13px;
}

.additional-services-three__content h3 {
  font-size: 35px;
  font-weight: 600;
  line-height: 35px;
}

.additional-services-three__content h3 span {
  font-size: 28px;
}

/*--------------------------------------------------------------
# Project Page One
--------------------------------------------------------------*/
.project-page-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.project-page-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.project-page-one .section-title {
  margin-bottom: 29px;
}

.project-page-one .section-title__tagline {
  margin-left: 0;
}

.project-page-one .section-title__tagline:after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.project-page-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.project-page-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
}

.project-page-one__img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 122, 239, 0.9);
  border-radius: 5px;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
}

.project-page-one__single:hover .project-page-one__img:before {
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.project-page-one__img>img {
  width: 100%;
  border-radius: 5px;
}

.project-page-one__content {
  position: absolute;
  left: 35px;
  bottom: 26px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 300ms ease 100ms;
  transition: all 300ms ease 100ms;
  z-index: 2;
}

.project-page-one__single:hover .project-page-one__content {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.project-page-one__sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.project-page-one__title {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--endreox-font);
}

.project-page-one__title a {
  color: #fff;
}

.project-page-one__button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateX(20deg) translateX(100%);
  transform: perspective(400px) rotateX(20deg) translateX(100%);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-page-one__single:hover .project-page-one__button {
  opacity: 1.0;
  -webkit-transform: perspective(400px) rotateX(0deg) translateX(0%);
  transform: perspective(400px) rotateX(0deg) translateX(0%);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-page-one__button a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--endreox-white);
  border-radius: 5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.project-page-one__button a:hover {
  background-color: #CE9B4E;
}

.project-page-one__button a+a {
  margin-top: 5px;
}

.project-page-one__button a>img {
  width: auto;
}

/*--------------------------------------------------------------
# Project Page Two
--------------------------------------------------------------*/
.project-page-two {
  position: relative;
  display: block;
  padding: 120px 0 72px;
  z-index: 1;
}

.project-page-two .section-title__tagline {
  margin-left: 0;
}

.project-page-two .section-title__tagline:after {
  position: absolute;
  right: -40px;
  top: 7px;
  width: 30px;
  height: 2px;
  background-color: var(--endreox-primary);
  content: "";
}

.project-page-two__single {
  position: relative;
  display: block;
  margin-bottom: 48px;
}

.project-page-two__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
}

.project-page-two__img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 122, 239, 0.9);
  border-radius: 5px;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
}

.project-page-two__single:hover .project-page-two__img:before {
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.project-page-two__img>img {
  width: 100%;
  border-radius: 5px;
}

.project-page-two__button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateX(20deg) translateX(100%);
  transform: perspective(400px) rotateX(20deg) translateX(100%);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-page-two__single:hover .project-page-two__button {
  opacity: 1.0;
  -webkit-transform: perspective(400px) rotateX(0deg) translateX(0%);
  transform: perspective(400px) rotateX(0deg) translateX(0%);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-page-two__button a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--endreox-white);
  border-radius: 5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.project-page-two__button a:hover {
  background-color: #CE9B4E;
}

.project-page-two__button a+a {
  margin-top: 5px;
}

.project-page-two__button a>img {
  width: auto;
}

.project-page-two__content {
  position: relative;
  display: block;
  margin-top: 27px;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 300ms ease 100ms;
  transition: all 300ms ease 100ms;
  z-index: 2;
}

.project-page-two__single:hover .project-page-two__content {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.project-page-two__title {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--endreox-font);
  line-height: 20px;
  margin-bottom: 4px;
}

.project-page-two__title a {
  color: #242424;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-page-two__title a:hover {
  color: var(--endreox-base);
}

.project-page-two__sub-title {
  font-size: 18px;
  color: #242424;
  line-height: 18px;
}

/*--------------------------------------------------------------
# Project Page Three
--------------------------------------------------------------*/
.project-page-three {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.project-page-three__top {
  position: relative;
  display: block;
}

.project-page-three__top .section-title {
  margin-bottom: 52px;
}

.project-page-three__sec-title-text {
  padding-top: 9px;
}

.project-page-three__filter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.project-page-three__filter.style1 li {
  position: relative;
  display: block;
  padding-left: 20px;
}

.project-page-three__filter.style1 li+li {
  margin-top: 12px;
}

.project-page-three__filter.style1 li .filter-text {
  position: relative;
  display: inline-block;
  color: #172a54;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
}

.project-page-three__filter.style1 li .filter-text:before {
  position: absolute;
  top: 17px;
  left: -20px;
  content: "";
  opacity: 1;
  height: 1px;
  width: 10px;
  background-color: #172a54;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.project-page-three__filter.style1 li:hover .filter-text:before {
  opacity: 1;
}

.project-page-three__filter.style1 li:hover .filter-text,
.project-page-three__filter.style1 li.active .filter-text {
  color: #172a54;
}

.project-page-three__filter.style1 li.active .filter-text:before {
  opacity: 1;
}

.project-page-three__bottom {
  position: relative;
  display: block;
}

.project-page-three__left {
  position: relative;
  display: block;
  margin-top: -10px;
}

.project-page-three__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.project-page-three__img {
  position: relative;
  display: block;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.project-page-three__img:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 122, 239, 0.9);
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
}

.project-page-three__single:hover .project-page-three__img:before {
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.project-page-three__img img {
  width: 100%;
}

.project-page-three__button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateX(20deg) translateX(100%);
  transform: perspective(400px) rotateX(20deg) translateX(100%);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-page-three__single:hover .project-page-three__button {
  opacity: 1.0;
  -webkit-transform: perspective(400px) rotateX(0deg) translateX(0%);
  transform: perspective(400px) rotateX(0deg) translateX(0%);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: all;
  transition-property: all;
}

.project-page-three__button a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--endreox-white);
  border-radius: 5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.project-page-three__button a:hover {
  background-color: #CE9B4E;
}

.project-page-three__button a+a {
  margin-top: 5px;
}

.project-page-three__button a>img {
  width: auto;
}

.project-page-three__title-box {
  position: absolute;
  bottom: 28px;
  left: 30px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 300ms ease 100ms;
  transition: all 300ms ease 100ms;
  z-index: 2;
}

.project-page-three__single:hover .project-page-three__title-box {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.project-page-three__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  font-family: var(--endreox-font);
}

.project-page-three__title a {
  color: var(--endreox-white);
}

/*--------------------------------------------------------------
# Services Page
--------------------------------------------------------------*/
.services-page {
  position: relative;
  display: block;
  background-color: #ebeef3;
  padding: 15px 0 0;
}

.services-page__top {
  position: relative;
  display: block;
  margin-bottom: 70px;
}

.services-page__top-left {
  position: relative;
  display: block;
}

.services-page__top-left .section-title {
  margin-bottom: 0;
}

.services-page__top-right {
  position: relative;
  display: block;
  margin-top: 28px;
}

.services-page__bottom {
  position: relative;
  display: block;
}

.services-page__filter-box {
  position: relative;
  display: block;
  border-bottom: 1px solid #bdc5d1;
  padding-bottom: 9px;
  margin-bottom: 60px;
}

.services-page__filter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.services-page__filter.style1 li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.services-page__filter.style1 li+li {
  margin-left: 60px;
}

.services-page__filter.style1 li .filter-text {
  position: relative;
  display: inline-block;
  color: var(--endreox-black);
  font-size: 14px;
  font-weight: 700;
  font-family: var(--endreox-font-two);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
}

.services-page__filter.style1 li .filter-text:before {
  position: absolute;
  bottom: -10px;
  left: -10px;
  right: -10px;
  content: "";
  opacity: 0;
  height: 2px;
  background-color: var(--endreox-primary);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.services-page__filter.style1 li:hover .filter-text:before {
  opacity: 1;
}

.services-page__filter.style1 li:hover .filter-text,
.services-page__filter.style1 li.active .filter-text {
  color: #000000;
}

.services-page__filter.style1 li.active .filter-text:before {
  opacity: 1;
}

.services-page__bottom .services-two__single {
  background-color: #ffffff;
  margin-bottom: 30px;
}

.services-page__bottom .services-two__single:hover {
  background-color: #58ABCF;
}

.services-page__customer-service {
  position: relative;
  display: block;
  padding: 79px 0 113px;
}

.services-page__customer-service-title {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
}

.services-page__customer-service-text-1 {
  font-size: 20px;
  color: #242424;
  line-height: 28px;
  padding-top: 25px;
  padding-bottom: 37px;
}

.services-page__customer-service-text-2 {
  font-size: 24px;
  color: #242424;
  font-weight: 500;
  line-height: 32px;
}

/*--------------------------------------------------------------
# Plumbing Installation
--------------------------------------------------------------*/
.electrical-panels {
  position: relative;
  display: block;
  padding: 120px 0 50px;
}

/* 
.electrical-panels__left {
  position: relative;
  display: block;
  margin-left: 200px;
  margin-right: 25px;
} */

.electrical-panels__img-box {
  position: relative;
  display: block;
}

.electrical-panels__img-one {
  position: relative;
  display: block;
}

.electrical-panels__img-one img {
  width: 100%;
}

.electrical-panels__img-two {
  position: absolute;
  bottom: 0;
  left: -315px;
}

.electrical-panels__img-two img {
  width: auto;
}

.electrical-panels__right {
  position: relative;
  display: block;
  margin-left: 45px;
  margin-bottom: 20px;
}

.secondsection {
  margin-top: 30px;
  margin-bottom: 30px;


}


.electrical-panels__right .section-title {
  margin-bottom: 19px;
}

.electrical-panels__text-1 {
  color: #444444;
}

.electrical-panels__points {
  position: relative;
  display: block;
  margin-top: 22px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.electrical-panels__points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
}

.electrical-panels__points li+li {
  margin-top: 5px;
}

.electrical-panels__points li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.electrical-panels__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--endreox-primary);
}

.electrical-panels__points li .text {
  margin-left: 20px;
}

.electrical-panels__points li .text p {
  font-size: 20px;
  color: #242424;
  font-weight: 500;
}

/*--------------------------------------------------------------
# About Quality
--------------------------------------------------------------*/
.about-quality {
  position: relative;
  display: block;
  background-color: #f8f8fa;
}

.about-quality__left {
  position: relative;
  display: block;
  margin-right: 50px;
  margin-left: -375px;
}

.about-quality__img {
  position: relative;
  display: block;
}

.about-quality__img img {
  width: 100%;
}

.about-quality__right {
  position: relative;
  display: block;
  margin-left: 20px;
  padding-top: 120px;
}

.list-unstyled.main-slider__three-facts-list svg {

  fill: #ffffff;
  height: 2rem;
  width: 2rem;

}

.list-unstyled.contact-page__content-info svg {

  fill: #58ABCF;
  height: 2rem;
  width: 2rem;

}


.about-one__points-icon svg path {

  stroke: #CE9B4E;


}

.about-one__call-box svg path {


  fill: #58ABCF;
}

.why-choose-one__count-box svg path {

  fill: #ffffff;
  stroke: #CE9B4E;
}



.about-quality__right .section-title {
  margin-bottom: 19px;
}

.about-quality__text-1 {
  font-size: 20px;
}

.about-quality__text-2 {
  font-size: 20px;
  padding-top: 22px;
  padding-bottom: 35px;
}

.about-quality__points {
  position: relative;
  display: block;
}

.about-quality__points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-quality__points li+li {
  margin-top: 11px;
}

.about-quality__points li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  color: var(--endreox-primary);
  border-radius: 50%;
  border: 2px solid var(--endreox-primary);
  font-size: 10px;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;
  -webkit-animation: slide 0.3s;
  animation: slide 0.3s;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  background: #010820;
  z-index: 999;
}

.about-quality__points li .text {
  margin-left: 20px;
}

.about-quality__points li .text p {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
}

/*--------------------------------------------------------------
# Video Gallery
--------------------------------------------------------------*/
.video-gallery {
  position: relative;
  display: block;
  padding: 120px 0 111px;
}

.video-gallery__left {
  position: relative;
  display: block;
  margin-right: 30px;
  margin-top: -11px;
}

.video-gallery__title-one {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
  margin-bottom: 18px;
}

.video-gallery__text-1 {
  padding-bottom: 32px;
}

.video-gallery__img-boxes {
  position: relative;
  display: block;
}

.video-gallery__img-boxes .row {
  --bs-gutter-x: 15px;
}

.video-gallery__img-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.video-gallery__img-single img {
  width: 100%;
}

.video-gallery__video-link {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 2;
}

.video-gallery__video-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 57px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  font-size: 20px;
  color: var(--endreox-white);
  background-color: #dcd6d3;
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 1;
}

.video-gallery__video-icon:hover {
  background-color: var(--endreox-black);
  color: var(--endreox-white);
}

.video-gallery__video-icon:before {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background-color: var(--endreox-base);
  content: "";
  border-radius: 50%;
  z-index: -1;
}

.video-gallery__video-link .ripple,
.video-gallery__video-icon .ripple:before,
.video-gallery__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 57px;
  height: 57px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--endreox-base-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--endreox-base-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--endreox-base-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--endreox-base-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  border-radius: 50%;
}

.video-gallery__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-gallery__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.video-gallery__service {
  position: relative;
  display: block;
  padding-top: 39px;
}

.video-gallery__service-title {
  font-size: 45px;
  font-weight: 900;
  line-height: 55px;
  margin-bottom: 18px;
}

.video-gallery__service-points-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 24px;
}

.video-gallery__service-points {
  position: relative;
  display: block;
  float: left;
}

.video-gallery__service-points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-gallery__service-points li+li {
  margin-top: 8px;
}

.video-gallery__service-points li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-gallery__service-points li .icon i {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: var(--endreox-primary);
}

.video-gallery__service-points li .text {
  margin-left: 20px;
}

.video-gallery__service-points li .text p {
  color: #242424;
  font-weight: 700;
  font-family: var(--endreox-font-two);
}

.video-gallery__service-points--2 {
  margin-left: 115px;
}

.video-gallery__right {
  position: relative;
  display: block;
}

.video-gallery__right-sidebar {
  position: relative;
  display: block;
}

.video-gallery__right-content {
  position: relative;
  display: block;
}

.video-gallery__right-content-img {
  position: relative;
  display: block;
}

.video-gallery__right-content-img img {
  width: 100%;
}

.video-gallery__right-content-img::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  height: 85px;
  background-color: var(--endreox-primary);
  -webkit-clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  z-index: 2;
}

.video-gallery__right-content-img::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  height: 65px;
  background-color: #001b3b;
  -webkit-clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  z-index: 2;
}

.video-gallery__right-content-title {
  position: relative;
  display: block;
  background-color: #001b3b;
  padding-top: 18px;
  padding-left: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}

.video-gallery__right-content-title h3 {
  font-size: 30px;
  color: var(--endreox-white);
  font-weight: 700;
  line-height: 32px;
}

.video-gallery__right-content-title h3 span {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}

.video-gallery__right-content-title h2 {
  font-size: 32px;
  color: var(--endreox-white);
  font-weight: 700;
  line-height: 32px;
  margin-top: 12px;
}

.video-gallery__right-content-btn-box {
  position: relative;
  display: block;
  margin-top: 25px;
  float: right;
}

.video-gallery__right-content-btn {
  padding-left: 30px;
  padding-right: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.video-gallery__right-content-btn:hover {
  color: var(--endreox-base);
}

.video-gallery__right-content-btn span {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  color: var(--endreox-white);
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  top: -1px;
  margin-left: 13px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.video-gallery__right-content-btn:hover span {
  border: 2px solid var(--endreox-base);
  color: var(--endreox-base);
}

.video-gallery__right-content-btn::before {
  background-color: var(--endreox-white);
}

.video-gallery__right-sidebar-download {
  position: relative;
  display: block;
  margin-top: 57px;
}

.video-gallery__right-sidebar-download h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 25px;
  color: #242424;
}

.video-gallery__download-btn {
  padding-left: 25px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: capitalize;
  font-family: var(--endreox-font);
  font-size: 19px;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
}

.video-gallery__download-btn span {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 10px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  margin-left: 10px;
}

/*--------------------------------------------------------------
# Service Details
--------------------------------------------------------------*/
.service-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.service-details__left {
  position: relative;
  display: block;
}

.service-details__sidebar {
  position: relative;
  display: block;
}

.service-details__page-links {
  position: relative;
  display: block;
  background-color: #edf0f9;
  padding: 30px 35px 30px;
  border-radius: 8px;
}

.service-details__title {
  position: relative;
  display: block;
  border-bottom: 1px solid #d1d3dd;
  padding-bottom: 22px;
}

.service-details__title h3 {
  position: relative;
  display: inline-block;
  font-size: 28px;
  color: var(--endreox-black);
  font-weight: 700;
  font-family: var(--endreox-font);
  line-height: 28px;
}

.service-details__title h3:before {
  content: "";
  position: absolute;
  top: 18px;
  right: -40px;
  width: 20px;
  height: 2px;
  background-color: var(--endreox-black);
}

.service-details__page-links-list {
  position: relative;
  display: block;
}

.service-details__page-links-list li {
  position: relative;
  display: block;
}

.service-details__page-links-list li a {
  font-size: 18px;
  color: var(--endreox-black);
  font-weight: 600;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d1d3dd;
  padding: 14px 0 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-details__page-links-list li:hover a {
  color: #58ABCF;
}

.service-details__page-links-list li.active a {
  color: #58ABCF;
}

.service-details__page-links-list li:last-child a {
  border-bottom: 0;
  padding: 8px 0 0px;
}

.service-details__contact-info {
  position: relative;
  display: block;
  background-color: var(--endreox-black);
  border-radius: 8px;
  padding: 41px 35px 45px;
  margin-top: 50px;
  margin-bottom: 47px;
}

.service-details__contact-info-list {
  position: relative;
  display: block;
}

.service-details__contact-info-list li {
  position: relative;
  display: block;
}

.service-details__contact-info-list li+li {
  margin-top: 18px;
}

.service-details__contact-info-list li h5 {
  font-size: 18px;
  font-weight: 600;
  color: var(--endreox-white);
  font-family: var(--endreox-font);
  line-height: 18px;
  margin-bottom: 4px;
}

.service-details__contact-info-list li p {
  color: var(--endreox-white);
  line-height: 26px;
}

.service-details__contact-info-list li p a {
  color: var(--endreox-white);
}

.service-details__contact-btn-box {
  position: relative;
  display: block;
  margin-top: 34px;
}

.service-details__contact-btn {
  font-size: 14px;
  padding: 5px 18px 5px;
}

.service-details__contact-btn:hover {
  color: var(--endreox-base);
}

.service-details__contact-btn:before {
  background-color: var(--endreox-white);
}

.service-details__download {
  position: relative;
  display: block;
}

.service-details__download-title {
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 35px;
}

.service-details__download-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  z-index: 1;
}

.service-details__download-btn a:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -45px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100px;
  width: 100px;
  background-color: rgba(var(--endreox-black-rgb), 0.2);
  border-radius: 50%;
  z-index: -1;
}

.service-details__download-btn a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--endreox-primary);
  font-size: 21px;
  color: var(--endreox-white);
  font-weight: 600;
  padding-left: 24px;
  padding-right: 13px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 4px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.service-details__download-btn a span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-size: 10px;
  border: 2px solid var(--endreox-white);
  border-radius: 50%;
  margin-left: 32px;
}

.service-details__download-btn a:hover {
  background-color: var(--endreox-base);
}

.service-details__right {
  position: relative;
  display: block;
  margin-left: 30px;
}

.service-details__content-box {
  position: relative;
  display: block;
}

.service-details__img-box {
  position: relative;
  display: block;
}

.service-details__img-box img {
  width: 100%;
}

.service-details__content {
  position: relative;
  display: block;
  margin-top: 61px;
}

.service-details__title-2 {
  font-size: 35px;
  font-weight: 900;
  line-height: 35px;
  margin-bottom: 14px;
}

.service-details__text-1 {
  color: #444444;
}

.service-details__text-2 {
  color: #444444;
  padding-top: 27px;
}

.service-details__feature {
  position: relative;
  display: block;
  padding-top: 56px;
}

.service-details__feature-left {
  position: relative;
  display: block;
  margin-top: -3px;
}

.service-details__feature-title {
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  margin-bottom: 16px;
}

.service-details__feature-text {
  color: #444444;
}

.service-details__feature-list {
  position: relative;
  display: block;
  margin-top: 15px;
}

.service-details__feature-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.service-details__feature-list li+li {
  margin-top: 6px;
}

.service-details__feature-list li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border: 1px solid #757779;
  border-radius: 50%;
  color: #a6cde7;
  font-size: 10px;
}

.service-details__feature-list li .text {
  margin-left: 20px;
}

.service-details__feature-list li .text p {
  color: #444444;
  font-weight: 600;
}

.service-details__feature-righ {
  position: relative;
  display: block;
}

.service-details__feature-img {
  position: relative;
  display: block;
}

.service-details__feature-img img {
  width: 100%;
}

.service-details__points-box {
  position: relative;
  display: block;
  border-top: 1px solid #ced2d7;
  border-bottom: 1px solid #ced2d7;
  padding-top: 44px;
  padding-bottom: 54px;
  margin-top: 53px;
}

.service-details__points-text {
  font-size: 19px;
  font-weight: 500;
  color: var(--endreox-black);
  line-height: 25px;
}

.service-details__points {
  position: relative;
  display: block;
  margin-top: 24px;
}

.service-details__points li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.service-details__points li+li {
  margin-top: 5px;
}

.service-details__points li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  background-color: #e34d4c;
  border-radius: 50%;
  font-size: 10px;
  color: var(--endreox-white);
}

.service-details__points li .text {
  margin-left: 20px;
}

.service-details__feature-two {
  position: relative;
  display: block;
  margin-top: 60px;
}

.service-details__feature-two .row {
  --bs-gutter-x: 0px;
}

.service-details__feature-two-single {
  position: relative;
  display: block;
  border: 1px solid #e9e9e9;
  padding: 26px 20px 23px;
  padding-right: 40px;
  background-color: var(--endreox-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.service-details__feature-two-single:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(245, 245, 245, 0.5);
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: -1;
}

.service-details__feature-two-single:hover:before {
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.service-details__feature-two-single-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.service-details__feature-two-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.service-details__feature-two-icon span {
  position: relative;
  display: inline-block;
  font-size: 60px;
  color: var(--endreox-primary);
}

.service-details__feature-two-content {
  margin-left: 15px;
}

.service-details__feature-two-title {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--endreox-font);
  line-height: 20px;
  margin-bottom: 10px;
}

.service-details__feature-two-text {
  font-size: 17px;
  color: #58ABCF;
  line-height: 27px;
}

.service-details__feature-two-single.border-left-none {
  border-left: 0;
}

.service-details__faq {
  position: relative;
  display: block;
  margin-top: 48px;
}

.service-details__faq h2 {
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  margin-bottom: 30px;
}

.service-details__faq .faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  border-radius: 0;
  border: 1px solid #d4d4d4;
}

.service-details__faq .faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 11px 20px 11px;
  padding-left: 55px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.service-details__faq .faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #242424;
  font-family: var(--endreox-font);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-details__faq .faq-one-accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.service-details__faq .faq-one-accrodion .accrodion-title h4::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px;
  color: var(--endreox-black);
  position: absolute;
  top: 50%;
  left: -45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-align: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.service-details__faq .faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f068";
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.service-details__faq .faq-one-accrodion .accrodion-content {
  position: relative;
  padding: 0px 55px 17px;
  padding-right: 40px;
  margin-top: -3px;
}

.service-details__faq .faq-one-accrodion .accrodion-content p {
  margin: 0;
  color: #444444;
  font-size: 17px;
}

/*--------------------------------------------------------------
# Team Page
--------------------------------------------------------------*/
.team-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.team-page .row {
  --bs-gutter-x: 10px;
}

.team-page .section-title {
  margin-bottom: 52px;
}

.team-page__sec-title-text {
  padding-top: 9px;
}

.team-page .team-one__title-box {
  background-color: var(--endreox-base);
}

.team-page .team-one__content-box {
  background-color: var(--endreox-base);
}

.team-page .team-one__name a:hover {
  color: var(--endreox-primary);
}

/*--------------------------------------------------------------
# Testimonial Page
--------------------------------------------------------------*/
.testimonials-page {
  position: relative;
  display: block;
  padding: 120px 0 60px;
}

.testimonials-page__top {
  position: relative;
  display: block;
  margin-bottom: 49px;
}

.testimonials-page__top-left {
  position: relative;
  display: block;
}

.testimonials-page__top-left .section-title {
  margin-bottom: 0;
}

.testimonials-page__top-right {
  position: relative;
  display: block;
  margin-top: 29px;
}

.testimonials-page__top-text {
  color: #081037;
  font-size: 20px;
}

.testimonials-page__bottom {
  position: relative;
  display: block;
}

.testimonials-page__bottom .testimonial-one__single {
  margin-bottom: 49px;
}

.testimonials-page__bottom .testimonial-one__inner {
  padding: 44px 35px 50px;
}

.testimonials-page__bottom .testimonial-one__client-info {
  margin-left: 38px;
}

.testimonials-page__bottom .testimonial-one__client-rating {
  margin-left: 45px;
}

.testimonials-page__bottom .testimonial-one__client-content h3 {
  color: var(--endreox-gray);
}

.testimonials-page__bottom .testimonial-one__client-content span {
  color: var(--endreox-gray);
}

/*--------------------------------------------------------------
# Appointment Page
--------------------------------------------------------------*/
.appointment-page {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 120px 0 120px;
  z-index: 10;
}

.appointment-title {
  position: relative;
  display: block;
  margin-top: -9px;
  padding-bottom: 52px;
}

.appointment-title h2 {
  color: #0e2a4e;
  font-size: 45px;
  line-height: 53px;
  font-weight: 900;
  margin: 0 0 20px;
}

.appointment-title p {
  margin: 0;
}

.appointment-form-box {
  position: relative;
  display: block;
  max-width: 790px;
  width: 100%;
}

.appointment-form .single-box .inner-title {
  position: relative;
  display: block;
  padding-bottom: 16px;
}

.appointment-form .single-box .inner-title h3 {
  color: var(--endreox-black);
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  font-family: var(--endreox-font);
}

.appointment-form {
  position: relative;
  display: block;
}

.appointment-form .single-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.appointment-form .single-box.selectbox {
  overflow: inherit;
}

.appointment-form .single-box .jobsite-address input {
  margin-bottom: 20px;
}

.appointment-checkbox {
  position: relative;
  display: block;
}

.appointment-checkbox .single-checkbox {
  position: relative;
  float: left;
  margin-right: 30px;
}

.appointment-checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: #444444;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  cursor: pointer;
  font-family: var(--endreox-font);
}

.appointment-checkbox input[type="checkbox"] {
  display: none;
}

.appointment-checkbox input[type="checkbox"]+label span {
  position: absolute;
  display: block;
  top: 8px;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #f4f4f4;
  border: 1px solid #8e8f8f;
  cursor: pointer;
  border-radius: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.appointment-checkbox label span:before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  color: #000000;
  font-size: 9px;
  line-height: 13px;
  text-align: center;
  border-radius: 50%;
  opacity: 0;
  background-color: transparent;
  font-weight: 900;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.appointment-checkbox input[type="checkbox"]:checked+label span {
  border-color: #16232a;
}

.appointment-checkbox input[type="checkbox"]:checked+label span:before {
  opacity: 1;
}

.appointment-form input[type="text"],
.appointment-form input[type="email"],
.appointment-form textarea {
  position: relative;
  display: block;
  background: #ffffff;
  border: 1px solid #dddddd;
  width: 100%;
  height: 40px;
  color: #444444;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: var(--endreox-font);
}

.appointment-form textarea {
  height: 180px;
}

.appointment-form .date-box {
  position: relative;
  display: block;
  width: 270px;
}

.appointment-form .date-box .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  color: #898989;
  font-size: 16px;
}

.appointment-form .date-box .icon span:before {
  position: relative;
  top: 2px;
}

.appointment-form .time-box {
  position: relative;
  display: block;
  width: 270px;
}

.appointment-form .time-box .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  color: #898989;
  font-size: 16px;
}

.appointment-form .time-box .icon span:before {
  position: relative;
  top: 2px;
}

.newsletter-checkbox {
  position: relative;
  display: block;
}

.newsletter-checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: #444444;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  cursor: pointer;
  font-family: var(--endreox-font);
}

.newsletter-checkbox input[type="checkbox"] {
  display: none;
}

.newsletter-checkbox input[type="checkbox"]+label span {
  position: absolute;
  display: block;
  top: 8px;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #f4f4f4;
  border: 1px solid #8e8f8f;
  cursor: pointer;
  border-radius: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.newsletter-checkbox label span:before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  color: #000000;
  font-size: 9px;
  line-height: 13px;
  text-align: center;
  border-radius: 50%;
  opacity: 0;
  background-color: transparent;
  font-weight: 900;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.newsletter-checkbox input[type="checkbox"]:checked+label span {
  border-color: #16232a;
}

.newsletter-checkbox input[type="checkbox"]:checked+label span:before {
  opacity: 1;
}

.appointment-form .select-box {
  position: relative;
  display: block;
  width: 100%;
}

.appointment-form .nice-select {
  position: relative;
  display: block;
  background-color: #ffffff;
  border-radius: 0px;
  border: solid 1px #dddddd;
  width: 100%;
  height: 40px;
  color: #444444;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-family: var(--endreox-font);
}

.appointment-form .nice-select:after {
  position: absolute;
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  color: #aeaeae;
  font-size: 15px;
  top: 0px;
  right: 25px;
  bottom: 0;
  margin: 0px;
  font-weight: 700;
  border: none !important;
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}

.appointment-form .nice-select .list {
  background-color: #ffffff;
  border-radius: 0px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0px 0 0px;
  margin-top: 10px;
}

.appointment-form .nice-select .option {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  line-height: 40px;
  min-height: 40px;
}

.appointment-form-button {
  position: relative;
  display: block;
}

.appointment-form-button .thm-btn {
  border: none;

}

/* Date Picker Css */
#ui-datepicker-div.ui-widget-content {
  background: #fff none repeat scroll 0 0;
  border: 1px solid var(--endreox-black);
  color: #252525;
  font-size: 14px;
  border-radius: 0px;
  width: 270px;
  padding: 5px;
}

#ui-datepicker-div.ui-datepicker .ui-datepicker-header {
  background: var(--endreox-base) none repeat scroll 0 0;
  border: 2px solid var(--endreox-base);
  border-radius: 0;
  color: var(--endreox-white);
  font-weight: 700;
  padding: 5px 0;
  position: relative;
}

.ui-datepicker td a {
  color: var(--endreox-black) !important;
  text-align: center;
  background-image: none !important;
  background: #f5f5f5 !important;
  border: 1px solid #f9f9f9 !important;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid var(--endreox-black) !important;
  background: var(--endreox-black) !important;
  color: var(--endreox-white) !important;
}

.ui-datepicker .ui-datepicker-prev {
  left: 5px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  border-radius: 30%;
  height: 20px;
  position: absolute;
  top: 7px;
  width: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-next {
  right: 5px;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  top: 7px;
  background: var(--endreox-white);
  border: none;
}

.ui-datepicker table {
  border-collapse: collapse;
  font-size: 13px;
  margin: 0 0 0.4em;
  width: 100%;
}

.ui-datepicker th {
  border: medium none;
  font-weight: 600;
  padding: 2px 3px;
  text-align: center;
}

.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active {
  background: var(--endreox-black);
  color: var(--endreox-white);
  border-color: var(--endreox-black);
}

#ui-datepicker-div.ui-widget {
  font-family: var(--endreox-font);
}

/*--------------------------------------------------------------
# Element Page Blog
--------------------------------------------------------------*/
.element-page-blog {
  background-color: #f4f4f4;
}

.element-page-blog .blog-one__content-box {
  background-color: #f4f4f4;
}

/*--------------------------------------------------------------
# Element Page Team
--------------------------------------------------------------*/
.element-page-team {
  padding-top: 0;
}

/*--------------------------------------------------------------
# Error Section
--------------------------------------------------------------*/
.error-section {
  background-color: #ecf1f5;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

@media (min-width: 768px) {
  .error-section {
    padding-top: 235px;
    padding-bottom: 235px;
  }
}

.error-section .container {
  position: relative;
}

.error-section__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
}

@media (min-width: 768px) {
  .error-section__shape {
    display: block;
  }
}

.error-section__shape img {
  mix-blend-mode: darken;
}

.error-section__title {
  margin: 0;
  font-size: 100px;
  font-weight: 900;
  line-height: 1em;
  color: var(--endreox-black);
  margin-bottom: 30px;
}

.error-section__title span {
  position: relative;
}

.error-section__title span img {
  display: none;
}

@media (min-width: 992px) {
  .error-section__title span img {
    display: inline;
    position: absolute;
    top: 40px;
    right: 100%;
  }
}

@media (min-width: 768px) {
  .error-section__title {
    font-size: 180px;
  }
}

@media (min-width: 1200px) {
  .error-section__title {
    font-size: 250px;
    margin: 0;
    margin-top: -33px;
  }
}

.error-section__text {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 1em;
  color: var(--endreox-black);
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .error-section__text {
    font-size: 55px;
  }
}

@media (min-width: 1200px) {
  .error-section__text {
    margin: 0;
    font-size: 66px;
    margin-bottom: 20px;
  }
}

.error-section__tagline {
  margin: 0;
  color: var(--endreox-gray);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .error-section__tagline {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .error-section__tagline {
    margin: 0;
    font-size: 28px;
    margin-bottom: 60px;
  }
}

.error-section__btn {
  padding: 22.5px 84px;
  border-radius: 37.5px;
}















/* Medium screen  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .appointment-one__list li:nth-child(4) {
    margin-left: 0;
  }

  .appointment-one__inner {
    padding-right: 80px;
  }

  .about-one__img-one {
    right: -320px;
  }

  .about-one__img-one img {
    width: 60%;
    height: 635px;
  }

  .services-one__right {
    margin-left: 0;
  }

  .we-can-help__right {
    margin-left: 0;
    margin-right: 0;
  }

  .counter-one__left {
    margin-left: 0;
    margin-right: 0;
  }

  .counter-one__year-box {
    padding: 11px 20px 31px;
  }

  .counter-one__year-title {
    font-size: 28px;
  }

  .counter-one__year-title span {
    font-size: 28px;
  }

  .why-choose-one__text-2 br {
    display: none;
  }

  .testimonial-one__right {
    margin-left: 0;
  }

  .site-footer__inner {
    padding: 0 0px 0;
  }

  .footer-widget__img-box {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .footer-widget__useful-links {
    margin-left: 0;
  }

  .footer-widget__services {
    margin-left: 0;
  }

  .footer-widget__about {
    margin-top: 0;
    margin-left: 0;
  }

  .site-footer__bottom {
    margin-top: 32px;
  }

  .experience-and-rating__right {
    padding-left: 30px;
  }

  .experience-and-rating__rating-text {
    font-size: 21px;
    margin-left: 20px;
  }

  .about-two__left {
    margin-right: 80px;
  }

  .about-two__points-2 {
    margin-left: 20px;
  }

  .about-two {
    padding: 120px 0 116px;
  }

  .project-two__right {
    margin-right: 0;
  }

  .project-two__carousel.owl-theme .owl-nav {
    left: 0;
    bottom: -94px;
  }

  .project-two {
    padding: 120px 0 314px;
  }

  .contact-bar__inne {
    padding-left: 0;
    flex-direction: column;
  }

  .contact-bar__right {
    margin-top: 30px;
  }

  .team-one__right {
    margin-left: 0;
  }

  .coupons__left {
    margin-bottom: 25px;
  }

  .services-four__right {
    margin-top: 64px;
  }

  .services-four__single {
    padding: 60px 35px 46px;
  }

  .additional-services__two-single {
    margin-left: 0;
    margin-bottom: 22px;
  }

  .services-four {
    padding: 100px 0 90px;
  }

  .welcome-one__left {
    margin-right: 0;
  }

  .welcome-one__right {
    margin-right: 0;
  }

  .welcome-one__author-info-box {
    margin-left: 0;
    flex-direction: column;
  }

  .welcome-one__author-signeture {
    margin-left: 0;
  }

  .welcome-one__img-box-2 {
    margin-left: 0;
    margin-top: 35px;
  }

  .why-choose-two__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .why-choose-two__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .why-choose-two {
    padding: 120px 0 94px;
  }

  .contact-one__left .section-title__title {
    font-size: 38px;
    line-height: 48px;
  }

  .about-three__right {
    margin-left: 0;
  }

  .about-three {
    padding: 120px 0 115px;
  }

  .about-three__points li+li {
    margin-left: 15px;
  }

  .appointment-title h2 br {
    display: none;
  }

  .blog-grid .blog-page__meta li+li {
    margin-left: 25px;
  }

  .blog-grid .blog-page__meta li:before {
    left: -11px;
  }

  .service-details__feature-righ {
    margin-top: 25px;
  }

  .service-details__feature-two-single.border-left-none {
    border-left: 1px solid #e9e9e9;
    margin-top: 30px;
  }

  .electrical-panels__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .electrical-panels__img-two {
    left: 0;
  }

  .electrical-panels__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .electrical-panels {
    padding: 120px 0 112px;
  }

  .about-quality__left {
    margin-right: 0;
    margin-left: 0;
    padding-top: 120px;
  }

  .about-quality__right {
    padding-bottom: 113px;
  }

  .video-gallery__service-points--2 {
    margin-left: 30px;
  }

  .team-page__sec-title-text br {
    display: none;
  }


}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
 
  .appointment-one__list li:nth-child(3) {
    margin-left: 0;
  }

  .appointment-one__list li:nth-child(5) {
    margin-left: 0;
  }
  .page-header__inner h2{
    font-size: 30px;
    line-height: 35px;
  }
  .about-one__img-one {
    display: none;
  }
  .about-one__img-one img{
  height: 100%;
  width:100%;
  }

  .services-one__right {
    margin-left: 0;
    margin-top: 20px;
  }

  .services-two__right {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .services-two__btn-box {
    text-align: left;
  }

  .services-two__top .section-title {
    margin-bottom: 50px;
  }

  .we-can-help__right {
    margin-left: 0;
    margin-right: 0;
    margin-top: 42px;
  }

  .counter-one__left {
    margin-left: 0;
    margin-right: 0;
  }

  .counter-one__year-box {
    max-width: 400px;
  }

  .counter-one__right {
    margin-top: 58px;
  }

  .counter-one {
    padding: 70px 0 37px;
  }

  .why-choose-one__right {
    margin-top: 84px;
  }

  .testimonial-one__right {
    margin-left: 0;
    margin-top: 54px;
  }

  .site-footer__contact-points li:before {
    display: none;
  }

  .site-footer__contact-points {
    flex-direction: column;
    align-items: baseline;
  }

  .site-footer__contact-points li+li {
    margin-top: 20px;
  }

  .footer-widget__img-box {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .footer-widget__useful-links {
    margin-left: 0;
  }

  .footer-widget__services {
    margin-left: 0;
  }

  .footer-widget__about {
    margin-top: 0;
    margin-left: 0;
  }

  .site-footer__bottom {
    margin-top: 32px;
  }

  .experience-and-rating__left:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -1000000px;
    left: 99%;
    top: -19px;
    background-color: #ecba16;
    z-index: -1;
  }

  .experience-and-rating__right:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 99%;
    left: -100000px;
    top: 0;
    background-color: #1d61d7;
    z-index: -1;
  }

  .about-two__right {
    margin-top: 60px;
  }

  .services-three__right {
    margin-bottom: 42px;
  }

  .services-three__left .section-title {
    margin-bottom: 29px;
  }

  .counter-two {
    padding: 329px 0 86px;
  }

  .project-two__right {
    margin-right: 0;
  }

  .project-two__carousel.owl-theme .owl-nav {
    left: 0;
    bottom: -94px;
  }

  .project-two {
    padding: 120px 0 314px;
  }

  .contact-bar__inne {
    padding-left: 20px;
    padding-right: 70px;
    flex-direction: column;
  }

  .contact-bar__right {
    margin-top: 30px;
  }

  .team-one__right {
    margin-left: 0;
    margin-bottom: 42px;
  }

  .team-one__left .section-title {
    margin-bottom: 39px;
  }

  .team-one__right {
    margin-left: 0;
  }

  .coupons__left {
    margin-bottom: 25px;
  }

  .services-four__right {
    margin-top: 64px;
  }

  .services-four__single {
    padding: 60px 35px 46px;
  }

  .additional-services__two-single {
    margin-left: 0;
    margin-bottom: 22px;
  }

  .services-four {
    padding: 100px 0 90px;
  }

  .welcome-one__right {
    margin-top: 50px;
  }

  .why-choose-two__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .why-choose-two__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .why-choose-two {
    padding: 120px 0 94px;
  }

  .why-choose-two__left {
    margin-left: 0;
  }

  .counter-three__count-box {
    flex-direction: column;
  }

  .contact-one__left {
    margin-bottom: 41px;
  }

  .contact-one {
    padding: 120px 0 120px;
  }

  .about-three__right {
    margin-left: 0;
    margin-top: 55px;
  }

  .mission-one__inner {
    flex-direction: column;
    align-items: baseline;
  }

  .mission-one__content-box {
    margin-left: 0;
    margin-top: 20px;
  }

  .mission-one {
    padding: 120px 0 113px;
  }

  .appointment-title h2 br {
    display: none;
  }

  .sidebar {
    margin-left: 0;
  }

  .blog-grid .blog-page__meta {
    flex-direction: column;
  }

  .blog-grid .blog-page__meta li:before {
    display: none;
  }

  .blog-grid .blog-page__meta li+li {
    margin-left: 0;
  }

  .about-company__right {
    margin-top: 60px;
  }

  .company-history__border {
    height: 1050px;
  }

  .contact-page__right {
    margin-left: 0;
    margin-top: 56px;
  }

  .contact-page {
    padding: 120px 0 120px;
  }

  .faq-page__right {
    margin-top: 20px;
  }

  .ask-question__img {
    display: none;
  }

  .project-details__info-box {
    margin-top: 50px;
  }

  .service-details__right {
    margin-left: 0;
    margin-top: 40px;
  }

  .service-details__feature-righ {
    margin-top: 30px;
  }

  .service-details__feature-two-single.border-left-none {
    border-left: 1px solid #e9e9e9;
    margin-top: 30px;
  }

  .services-page__filter.style1 li+li {
    margin-left: 45px;
  }

  .electrical-panels__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .electrical-panels__img-two {
    left: 0;
  }

  .electrical-panels__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .electrical-panels {
    padding: 120px 0 112px;
  }

  .about-quality__left {
    margin-right: 0;
    margin-left: 0;
  }

  .about-quality__right {
    padding-bottom: 113px;
    margin-left: 0;
  }

  .video-gallery__service-points--2 {
    margin-left: 110px;
  }

  .video-gallery__right {
    margin-top: 50px;
  }

  .team-page__sec-title-text br {
    display: none;
  }





}




























@media only screen and (max-width: 991.98px){
.main-menu__main-menu-box .showLinks {
    background-color: #030721;
    display: flex;
    flex-direction: column;
    left: 50%;
    padding: 20px;
    position: absolute;
    text-align: left;
    -webkit-transform: translate(-50%,60%);
    transform: translate(-50%,56%);
    width: 100%;
    z-index: 1000;
}
.main-menu__main-menu-box .hideLinks {
  display: none;
}
.main-menu__main-menu-box{
  position: initial;
}
.main-menu .main-menu__list>li{
  padding-bottom: 0;
  padding-top: 0;
}
.main-menu .main-menu__list>li+li, .stricky-header .main-menu__list>li+li{
  margin-left: 0;
}
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
 .main-menu .main-menu__list>li>ul{
  visibility: inherit;
 }
.about-one__img-one {
    display: none;
  }

 .about-three__experience{
  right: 0;
  bottom: -5px;
 }
/* .main-menu__main-menu-box .showLinks {
  background-color: #dff1ff;
  display: block;
  display: flex;
  flex-direction: column;
  left: 49%;
  padding: 20px;
  position: absolute;
  text-align: center;
  -webkit-transform: translate(-50%,8%);
  transform: translate(-50%,8%);
  width: 100%;
  z-index: 1000;
} */
.page-header__inner h2{
  font-size: 30px;
  line-height: 35px;
}
  .section-title__title br {
    display: none;
  }

  .section-title__title {
    font-size: 30px;
    line-height: 40px;
  }

  .appointment-one__list li {
    float: none;
    max-width: 100%;
  }

  .appointment-one__list li+li {
    margin-left: 0;
  }

  .appointment-one__inner {
    padding-right: 15px;
  }

  .about-one__img-one img{
    height:330px;
    width:100%;
    }
    .about-one__img-one{
      transform: rotateY(182deg);
    }
    .about-one__call-box{
      max-width: 100%;
    }

  .about-one__left {
    margin-right: 0;
  }

  .about-one__points-box {
    flex-direction: column;
    align-items: baseline;
  }

  .about-one__points-list+.about-one__points-list {
    margin-left: 0;
    margin-top: 6px;
  }

  .about-one__bottom {
    flex-direction: column;
    align-items: baseline;
  }

  .about-one__years-box {
    margin-top: 20px;
    min-width: 100%;
  }
  .why-choose-one__img img{
    width: 100%;
    height: 100%;
  }
  .about-one__number {
    font-size: 23px;
    line-height: 33px;
  }
  .blog-one__img img{
    object-fit: contain;
  }
  .blog-one{
    padding: 120px 20px 40px;
  }
  .main-slider__three-facts-list li+li{
    margin-left: 4px;
  }
  .main-slider__three-facts-list li{
    padding: 7px 0;
    height: 60px;
    min-width: 40px;
  }
  .list-unstyled.main-slider__three-facts-list svg{
    width: 1em;
    height: 1em;
  }
  .main-slider__three-facts-list li h5{
    font-size: 5px;
    line-height: 8px;
  }
  
  .services-one__right {
    margin-left: 0;
    margin-top: 20px;
  }

  .services-two__right {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .services-two__btn-box {
    text-align: left;
  }

  .services-two__top .section-title {
    margin-bottom: 50px;
  }

  .project-one__filter {
    flex-direction: column;
  }

  .project-one__filter.style1 li+li {
    margin-left: 0;
    margin-top: 10px;
  }

  .we-can-help__right {
    margin-left: 0;
    margin-right: 0;
    margin-top: 42px;
  }

  .counter-one__left {
    margin-left: 0;
    margin-right: 0;
  }

  .counter-one__year-box {
    padding: 11px 15px 31px;
  }

  .counter-one__year-title {
    font-size: 29px;
  }

  .counter-one__year-title span {
    font-size: 30px;
  }

  .counter-one__right {
    margin-top: 58px;
  }

  .counter-one__single {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .counter-one__single:before {
    display: none;
  }

  .counter-one {
    padding: 70px 0 37px;
  }

  .why-choose-one__right {
    margin-top: 50px;
  }

  .why-choose-one__left {
    margin-right: 0;
  }

  .why-choose-one__count-box {
    display: none;
  }

  .why-choose-one__text-2 br {
    display: none;
  }

  .testimonial-one__right {
    margin-left: 0;
    margin-top: 54px;
  }

  .testimonial-one__inner {
    padding: 53px 15px 50px;
    flex-direction: column;
    align-items: baseline;
  }

  .testimonial-one__client-info {
    margin-left: 0;
    margin-top: 20px;
  }

  .testimonial-one__inner:before {
    left: 50px;
  }

  .testimonial-one__client-content {
    margin-left: 30px;
  }

  .site-footer__contact-points li:before {
    display: none;
  }

  .site-footer__contact-points {
    flex-direction: column;
    align-items: baseline;
  }

  .site-footer__contact-points li+li {
    margin-top: 20px;
  }

  .site-footer__inner {
    padding: 0 0px 0;
  }

  .footer-widget__img-box {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .footer-widget__useful-links {
    margin-left: 0;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .footer-widget__services {
    margin-left: 0;
  }

  .footer-widget__about {
    margin-top: 31px;
    margin-left: 0;
  }

  .site-footer__bottom {
    margin-top: 32px;
  }

  .site-footer__bottom-inner {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .site-footer__shape-1 {
    display: none;
  }

  .experience-and-rating__left:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -1000000px;
    left: 99%;
    top: -19px;
    background-color: #ecba16;
    z-index: -1;
  }

  .experience-and-rating__right:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 99%;
    left: -100000px;
    top: 0;
    background-color: #1d61d7;
    z-index: -1;
  }

  .experience-and-rating__experience {
    flex-direction: column;
    align-items: baseline;
  }

  .experience-and-rating__experience-text-box {
    margin-left: 0;
    margin-top: 15px;
  }

  .experience-and-rating__right {
    padding-left: 15px;
  }

  .about-two__left {
    margin-right: 0;
  }

  .about-two__right {
    margin-top: 80px;
  }

  .about-two__count-box {
    right: 0px;
  }

  .about-two__points-box {
    flex-direction: column;
    align-items: baseline;
  }

  .about-two__points-2 {
    margin-left: 0;
    margin-top: 10px;
  }

  .about-two {
    padding: 120px 0 116px;
  }

  .services-three__right {
    margin-bottom: 42px;
  }

  .services-three__left .section-title {
    margin-bottom: 29px;
  }

  .counter-two {
    padding: 329px 0 86px;
  }

  .project-two__right {
    margin-right: 0;
  }

  .project-two__carousel.owl-theme .owl-nav {
    left: 0;
    bottom: -94px;
  }

  .project-two {
    padding: 120px 0 314px;
  }

  .contact-bar__inne {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    margin-right: 0;
    text-align: center;
  }

  .contact-bar__right {
    margin-top: 30px;
  }

  .contact-bar__left {
    flex-direction: column;
  }

  .contact-bar__left-content {
    margin-left: 0;
    top: 0;
  }

  .contact-bar__title {
    font-size: 30px;
    line-height: 40px;
  }

  .contact-bar__call {
    flex-direction: column;
  }

  .contact-bar__call-number {
    font-size: 25px;
  }

  .contact-bar__call-content {
    margin-left: 0;
    margin-top: 25px;
  }

  .team-one__right {
    margin-left: 0;
    margin-bottom: 42px;
  }

  .team-one__left .section-title {
    margin-bottom: 39px;
  }

  .coupons__left {
    margin-bottom: 25px;
    margin-right: 0;
  }

  .testimonial-two__shape-1 {
    bottom: -46px;
  }

  .testimonial-two__client-info {
    margin-left: 0;
  }

  .services-four__right {
    margin-top: 64px;
  }

  .services-four__single {
    padding: 60px 35px 46px;
  }

  .additional-services__two-single {
    margin-left: 0;
    margin-bottom: 22px;
  }

  .services-four {
    padding: 100px 0 90px;
  }

  .additional-services__two-left {
    margin-bottom: 42px;
  }

  .welcome-one__left {
    margin-right: 0;
  }

  .welcome-one__right {
    margin-right: 0;
    margin-top: 50px;
  }

  .welcome-one__author-info-box {
    margin-left: 0;
    flex-direction: column;
  }

  .welcome-one__author-signeture {
    margin-left: 0;
  }

  .welcome-one__img-box-2 {
    margin-left: 0;
    margin-top: 35px;
  }

  .why-choose-two__right {
    margin-left: 0;
    margin-right: 0;
    margin-top: 60px;
  }

  .why-choose-two__text br {
    display: none;
  }

  .why-choose-two__points-box {
    flex-direction: column;
    align-items: baseline;
  }

  .why-choose-two__points+.why-choose-two__points {
    margin-left: 0;
    margin-top: 37px;
  }

  .why-choose-two {
    padding: 120px 0 94px;
  }

  .counter-three__count-box {
    flex-direction: column;
  }

  .contact-one__left {
    margin-bottom: 41px;
  }

  .contact-one {
    padding: 120px 0 120px;
  }

  .about-three__points {
    flex-direction: column;
    align-items: baseline;
  }

  .about-three__points li+li {
    margin-left: 0;
    margin-top: 20px;
  }

  .about-three__right {
    margin-left: 0;
    margin-top: 55px;
  }

  .mission-one__inner {
    flex-direction: column;
    align-items: baseline;
  }

  .mission-one__content-box {
    margin-left: 0;
    margin-top: 20px;
  }

  .mission-one__content {
    flex-direction: column;
    align-items: baseline;
  }

  .mission-one__sign {
    margin-left: 0;
    margin-top: 20px;
  }

  .appointment-title h2 br {
    display: none;
  }

  .sidebar {
    margin-left: 0;
  }

  .blog-page__meta {
    flex-direction: column;
  }

  .blog-page__meta li:before {
    display: none;
  }

  .blog-page__meta li+li {
    margin-left: 0;
  }

  .blog-details__meta {
    flex-direction: column;
  }

  .blog-details__meta li:before {
    display: none;
  }

  .blog-details__meta li+li {
    margin-left: 0;
  }

  .blog-detals__two-colum-text {
    flex-direction: column;
    align-items: baseline;
  }

  .blog-detals__two-colum-text li+li {
    margin-left: 0;
    margin-top: 20px;
  }

  .comment-one__single--four {
    padding-left: 40px;
  }

  .about-company__bottom-right {
    flex-direction: column;
    align-items: baseline;
  }

  .about-company__sign {
    margin-left: 0;
    margin-top: 25px;
  }

  .company-history__list li {
    padding-left: 0;
  }

  .company-history__year {
    position: relative;
    top: 0;
    margin-bottom: 20px;
  }

  .company-history__border {
    display: none;
  }

  .company-history__img-boxes {
    flex-direction: column;
    align-items: baseline;
  }

  .company-history__img-two {
    margin-left: 0;
  }

  .about-company__right {
    margin-top: 60px;
  }

  .contact-page__right {
    margin-left: 0;
    margin-top: 56px;
  }

  .contact-page {
    padding: 120px 0 120px;
  }

  .faq-page__right {
    margin-top: 20px;
  }

  .ask-question__img {
    display: none;
  }

  .project-details__info-box {
    margin-top: 50px;
    padding: 48px 25px 75px;
  }

  .service-details__right {
    margin-left: 0;
    margin-top: 40px;
  }

  .service-details__feature-righ {
    margin-top: 30px;
  }

  .service-details__feature-two-single.border-left-none {
    border-left: 1px solid #e9e9e9;
    margin-top: 30px;
  }

  .services-page__filter {
    flex-direction: column;
  }

  .services-page__filter.style1 li+li {
    margin-left: 0;
    margin-top: 25px;
  }

  .services-page__filter-box {
    padding-bottom: 35px;
  }

  .services-page__customer-service-text-1 br,
  .services-page__customer-service-text-2 br {
    display: none;
  }

  .electrical-panels__left {
    margin-left: 0;
    margin-right: 0;
  }

  .electrical-panels__img-two {
    display: none;
  }

  .electrical-panels__right {
    margin-left: 0;
    margin-top: 60px;
  }

  .electrical-panels {
    padding: 120px 0 0px;
  }

  .about-quality__left {
    margin-right: 0;
    margin-left: 0;
  }

  .about-quality__right {
    padding-bottom: 113px;
    margin-left: 0;
  }

  .video-gallery__service-points {
    float: none;
  }

  .video-gallery__service-points--2 {
    margin-left: 0;
    margin-top: 10px;
  }

  .video-gallery__right {
    margin-top: 50px;
  }

  .team-page__sec-title-text br {
    display: none;
  }

  .testimonials-page__bottom .testimonial-one__client-info {
    margin-left: 0;
  }





}


















@media only screen and (min-width: 1200px) and (max-width: 1217px) {
  .appointment-one__inner {
    padding-right: 58px;
  }
}


@media only screen and (min-width: 1420px) and (max-width: 1620px) {
  .about-one__img-one {
    right: -100px;
  }

  .about-one__img-one img {
    width: 85%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1419px) {
  .about-one__img-one {
    right: -220px;
  }

  .about-one__img-one img {
    width: 70%;
  }
}



/*--------------------------------------------------------------
# Slider All Responsice Css
--------------------------------------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider__img {
    top: 255px;
    right: -180px;
  }

  .main-slider__img img {
    width: 60%;
  }

  .main-slider__service-start {
    right: 240px;
  }

  .image-layer-three {
    display: none;
  }

  .main-slider-three__content {
    margin-right: 0;
    margin-left: 0;
  }

  .main-slider-three__content-left {
    margin-left: 0;
  }

  .main-slider-two__nav {
    align-items: flex-end;
  }
}






@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider__img {
    display: none;
  }

  .image-layer-three {
    display: none;
  }

  .main-slider-three__content {
    margin-right: 0;
    margin-left: 0;
  }

  .main-slider-three__content-left {
    margin-left: 0;
  }

  .main-slider-two__nav {
    align-items: flex-end;
  }
}



@media only screen and (max-width: 767px) {
  .footer-widget__useful-links-list{
    /* display: contents !important; */
  }
  .main-menu .main-menu__list{
    align-items: start;
  }
  .main-slider__title {
    font-size: 39px;
    line-height: 49px;
  }

  .main-slider__text br {
    display: none;
  }

  .main-slider__img {
    display: none;
  }

  .main-slider-two__shape-1 {
    display: none;
  }

  .main-slider-two__title {
    font-size: 34px;
    line-height: 44px;
  }

  .image-layer-three {
    display: none;
  }

  .main-slider-three__content {
    margin-right: 0;
    margin-left: 0;
  }

  .main-slider-three__content-left {
    margin-left: 0;
  }

  .main-slider-three__title {
    font-size: 34px;
    line-height: 46px;
  }

  .main-slider-three__text br {
    display: none;
  }

  .main-slider-three__btn-boxes {
    flex-direction: column;
    align-items: baseline;
  }

  .main-slider-three__btn-2 {
    margin-left: 0;
    margin-top: 20px;
  }

  .main-slider-three .container {
    padding-bottom: 160px;
  }

  .main-slider-three__nav {
    display: none;
  }

  .main-slider-two__nav {
    display: none;
  }
}




@media only screen and (min-width: 1435px) and (max-width: 1635px) {
  .main-slider__img {
    right: 110px;
  }

  .main-slider-three__content {
    margin-left: -50px;
  }

  .main-slider-three__content-left {
    margin-left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1434px) {
  .main-slider__img {
    right: -10px;
  }

  .main-slider__img img {
    width: 85%;
  }

  .main-slider-three__content {
    margin-left: -160px;
  }

  .main-slider-three__content-left {
    margin-left: 0px;
  }

}


@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .main-slider-two__nav {
    align-items: flex-end;
  }
}




/*--------------------------------------------------------------
# Main Menu All Responsice Css
--------------------------------------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu__wrapper {
    padding: 0 20px;
  }

  .main-menu__search-box {
    margin-left: 50px;
  }

  .main-menu-two__logo {
    margin-right: 50px;
  }

  .main-menu-two__wrapper {
    padding: 0px 20px;
  }

  .main-menu-three__left {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .scrollTop {
    height: 6.4047226798462384vw;
    width: 6.4047226798462384vw;
    padding: 0;
    right: 5px;
  }

  .appointment_btn {
    height: 6.4047226798462384vw;
    width: 6.4047226798462384vw;
    top: 12.329649vw;
    padding: 0;
    right: 5px;

  }

  .appointment_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .show_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .show_btn {
    top: 28.409648544755626vw;
  }

  .mail_btn {
    top: 20.329649vw;
    height: 6.4047226798462384vw;
    width: 6.4047226798462384vw;
    padding: 0;
    right: 5px;
  }

  .mail_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-menu__wrapper {
    padding: 0 20px;
  }
  .scrollTop {
    height: 6.4047226798462384vw;
    width: 6.4047226798462384vw;
    padding: 0;
    right: 5px;
  }

  .appointment_btn {
    height: 6.4047226798462384vw;
    width: 6.4047226798462384vw;
    top: 17.329649vw;
    padding: 0;
    right: 5px;

  }

  .appointment_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .show_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .show_btn {
    top: 33.409648544755626vw;
  }

  .mail_btn {
    top: 25.329649vw;
    height: 6.4047226798462384vw;
    width: 6.4047226798462384vw;
    padding: 0;
    right: 5px;
  }

  .mail_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .main-menu__wrapper-inner{
    flex-wrap: inherit;
  }
  .main-menu__logo img{
  width: 250px;
  }
  .main-menu__logo {
    margin-right: 0px;
  }

  .main-menu-two__wrapper {
    padding: 0px 20px;
  }

  .main-menu-two__logo {
    margin-right: 50px;
  }

  .main-menu-three__left {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .main-header-three__contact-box {
    display: none;
  }

  .main-header-three__middle-inner {
    justify-content: center;
  }

}


@media (max-width: 767px) {
  .handle-preloader .animation-preloader .txt-loading .letters-loading{
    font-size: 20px;
  }

  .main-menu__main-menu-box .menu-icon {
    display: block;
  }
  .scrollTop {
    height: 10.4047226798462384vw;
    width: 10.4047226798462384vw;
    padding: 0;
    right: 5px;
  }

  .appointment_btn {
    height: 10.4047226798462384vw;
    width: 10.4047226798462384vw;
    top: 34.329649vw;
    padding: 0;
    right: 5px;

  }

  .appointment_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .show_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .show_btn {
    top: 60.409648544755626vw;
  }

  .mail_btn {
    top: 47.329649vw;
    height: 10.4047226798462384vw;
    width: 10.4047226798462384vw;
    padding: 0;
    right: 5px;
  }

  .mail_btn svg {
    height: 3.404723vw;
    width: 3.404723vw;
  }

  .main-menu__right {
    display: none;
  }

  .main-menu__wrapper {
    padding: 15px 20px;
  }

  .main-menu__search-box {
    display: none;
  }

  .main-menu__logo {
    margin-right: 0;
  }

  .main-menu__logo img {
    width: 200px;

  }

  .sticky {
    height: 70px;
  }

  .main-slider__three-facts {
    max-width: 100%;
  }

  .main-menu__left {
    justify-content: space-between;
    flex: 1;
  }

  .main-menu-two__wrapper {
    padding: 0px 20px;
  }

  .main-menu-two__right {
    display: none;
  }

  .main-menu-two__logo {
    margin-right: 0;
  }

  .main-menu-two__search-box {
    display: none;
  }

  .main-menu-two__left {
    justify-content: space-between;
    flex: 1;
  }

  .main-header-three__top {
    display: none;
  }

  .main-header-three__contact-box {
    display: none;
  }

  .main-header-three__middle-inner {
    justify-content: center;
  }

  .main-menu-three__right {
    display: none;
  }

  .main-menu-three__left {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .main-menu-three__main-menu-box {
    justify-content: space-between;
  }

  .main-menu-three__search-box {
    margin-left: 0;
    margin-right: 0;
  }
}



@media only screen and (min-width: 1420px) and (max-width: 1620px) {
  .main-menu__wrapper {
    padding: 0 20px;
  }

  .main-menu__logo {
    margin-right: 30px;
  }
  .main-menu__call {
    display: none;
  }
}



@media only screen and (min-width: 1200px) and (max-width: 1419px) {
  .main-menu__wrapper {
    padding: 0 20px;
  }

  .main-menu__logo {
    margin-right: 0px;
  }

  .main-menu__call {
    display: none;
  }

  .main-menu__btn-box {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .main-menu-two__wrapper {
    padding: 0px 20px;
  }

  .main-menu-two .main-menu__list>li+li,
  .stricky-header.main-menu-two .main-menu__list>li+li {
    margin-left: 30px;
  }

  .main-menu-two__logo {
    margin-right: 30px;
  }
}












/* odometer */
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  position: relative
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  position: relative
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  visibility: hidden
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0)
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%)
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%)
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0)
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em
}

.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center
}

/* odometer */